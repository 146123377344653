<script setup lang="ts">
const props = defineProps<{
  icon?: string
  link?: string
}>()

const NuxtLink = resolveComponent("NuxtLink")
</script>

<template>
  <HeadlessMenuItem>
    <component
      :is="link ? NuxtLink : 'div'"
      as="span"
      :to="props.link ?? undefined"
      class="mb-2 flex cursor-pointer items-center gap-2 rounded px-2 py-1 transition-colors last-of-type:mb-0 hover:bg-ebony-600">
      <Icon v-if="props.icon" :name="props.icon" /><slot />
    </component>
  </HeadlessMenuItem>
</template>
