<script setup lang="ts">
const props = defineProps<{
  error: any
}>()

const appName = getAppName()

useHead({
  title: computed(() => {
    let title = ""
    switch (props.error.statusCode) {
      case 404:
        title = "Not Found"
        break
      case 403:
        title = "Access Denied"
        break
      default:
        title = "Something Went Wrong"
        break
    }

    return `${title} - ${appName}`
  }),
})

const errorMessages = computed(() => {
  switch (props.error.statusCode) {
    case 404:
      return {
        title: "Couldn't find the page you're looking for",
        subtitle: "It may have been moved or just doesn't exist any more.",
      }
    case 403:
      if (props.error.message === "licence") {
        return {
          title: "You do not have the licence needed to access this page",
          subtitle: "Contact support if you think this isn't correct",
        }
      }

      return {
        title: "You don’t have access to this page (anymore)",
        subtitle: "Contact support if you think this isn't correct",
      }
    default:
      const errorMessage =
        typeof props.error.message === "string"
          ? (props.error.message as string)
          : undefined

      if (errorMessage?.includes("fetch failed")) {
        return {
          title: "Couldn't connect to the server",
          subtitle:
            "Couldn't connect to the server. Please check your internet connection and try again later.",
          displayHomeButton: false,
        }
      }

      return {
        title: "Something went wrong",
        subtitle: "Please contact an administrator",
        displayStack: import.meta.dev,
        error: props.error.statusMessage ?? errorMessage,
      }
  }
})

console.error(props.error)

const handleError = () => clearError({ redirect: "/" })
</script>

<template>
  <div class="relative h-full overflow-hidden">
    <NavBar />
    <div
      class="container mx-auto -mt-20 flex h-full w-full flex-col justify-center px-4">
      <h1 class="mb-5 font-heading text-hero font-bold text-white">
        {{ errorMessages.title }}
      </h1>
      <p v-if="errorMessages.error" class="mb-5 text-ebony-100">
        Error: {{ errorMessages.error }}
      </p>
      <!-- Error is disabled as stack is from vue, not user input. Also only runs on dev. -->
      <!-- eslint-disable vue/no-v-html -->
      <div
        v-if="errorMessages.displayStack && error.stack"
        class="mb-5 text-dusk-100 [&>pre]:whitespace-pre-wrap"
        v-html="error.stack"></div>
      <!-- eslint-enable -->
      <p class="mb-10 text-ebony-100">{{ errorMessages.subtitle }}</p>
      <Button
        v-if="errorMessages.displayHomeButton !== false"
        to="/"
        variant="primary"
        @click="handleError"
        >Back to home</Button
      >
    </div>
    <CopyrightFooter variant="dark" class="fixed bottom-0" />
    <Background />
  </div>
</template>
