import type { ProjectInfo } from "~/types/api"

import { defineStore } from "pinia"

export const useLastPagesStore = defineStore("lastPages", {
  state: () => ({
    projectList: [] as ProjectInfo[],
  }),
  actions: {
    accessProject(projectInfo: ProjectInfo) {
      const projectIndex = this.projectList.findIndex(
        (item) => item.id === projectInfo.id,
      )

      if (projectIndex === -1) {
        this.projectList.unshift(projectInfo)
      } else {
        this.projectList.splice(projectIndex, 1)
        this.projectList.unshift(projectInfo)
      }
      if (this.projectList.length > 3) {
        this.projectList.pop()
      }
    },
  },
  persist: { key: "projects", paths: ["projectList"] },
})
