import { default as _91_46_46_46slug_93XOh4cis8EcMeta } from "/app/src/pages/[...slug].vue?macro=true";
import { default as accept_45termsgwagLyZS3oMeta } from "/app/src/pages/accept-terms.vue?macro=true";
import { default as index8H56KOCFHiMeta } from "/app/src/pages/admin/accounts/[id]/index.vue?macro=true";
import { default as usage3Y1urhlDspMeta } from "/app/src/pages/admin/accounts/[id]/usage.vue?macro=true";
import { default as createcHQ1DhY1KHMeta } from "/app/src/pages/admin/accounts/create.vue?macro=true";
import { default as indextcbMpNDxpmMeta } from "/app/src/pages/admin/accounts/index.vue?macro=true";
import { default as _91id_93rMMyzeikqDMeta } from "/app/src/pages/admin/announcements/[id].vue?macro=true";
import { default as createXAEMRPqSBXMeta } from "/app/src/pages/admin/announcements/create.vue?macro=true";
import { default as indexl55U4CsQHYMeta } from "/app/src/pages/admin/announcements/index.vue?macro=true";
import { default as indexwgywxERjK9Meta } from "/app/src/pages/admin/index.vue?macro=true";
import { default as _91id_93atLhGchffWMeta } from "/app/src/pages/admin/licence-plans/[id].vue?macro=true";
import { default as createZjypxvKRQzMeta } from "/app/src/pages/admin/licence-plans/create.vue?macro=true";
import { default as indexRRCHkKZ6cvMeta } from "/app/src/pages/admin/licence-plans/index.vue?macro=true";
import { default as _91id_932BS8suBk1FMeta } from "/app/src/pages/admin/licences/[id].vue?macro=true";
import { default as createqCMjgDRNv9Meta } from "/app/src/pages/admin/licences/create.vue?macro=true";
import { default as indexny4bzAJ4qDMeta } from "/app/src/pages/admin/licences/index.vue?macro=true";
import { default as _91id_93Cko5pRme1uMeta } from "/app/src/pages/admin/user-groups/[id].vue?macro=true";
import { default as createevqV9DPW9fMeta } from "/app/src/pages/admin/user-groups/create.vue?macro=true";
import { default as indexhEcanklkruMeta } from "/app/src/pages/admin/user-groups/index.vue?macro=true";
import { default as indexAKDo3Wf8ZpMeta } from "/app/src/pages/admin/user-invites/index.vue?macro=true";
import { default as invitesMYdXKmMFYMeta } from "/app/src/pages/admin/user-invites/invite.vue?macro=true";
import { default as _91id_93rTrONmfS65Meta } from "/app/src/pages/admin/users/[id].vue?macro=true";
import { default as createftbSESoe1IMeta } from "/app/src/pages/admin/users/create.vue?macro=true";
import { default as indexRCyTjEyR9EMeta } from "/app/src/pages/admin/users/index.vue?macro=true";
import { default as adminKPGIlZeS47Meta } from "/app/src/pages/admin.vue?macro=true";
import { default as _91token_93OGRYcCTWyhMeta } from "/app/src/pages/external-invite/[token].vue?macro=true";
import { default as forgot0id9L0C55aMeta } from "/app/src/pages/forgot.vue?macro=true";
import { default as indexdPwi6hPdMfMeta } from "/app/src/pages/index.vue?macro=true";
import { default as _91token_934IkuI9LT6NMeta } from "/app/src/pages/invite/[token].vue?macro=true";
import { default as login3hUYZUGxmUMeta } from "/app/src/pages/login.vue?macro=true";
import { default as mfaldkDdPwrEVMeta } from "/app/src/pages/mfa.vue?macro=true";
import { default as _91id_93gRKagT9v8jMeta } from "/app/src/pages/parts/batteries/[id].vue?macro=true";
import { default as createozandq5VcEMeta } from "/app/src/pages/parts/batteries/create.vue?macro=true";
import { default as indexRrbWoU0XxkMeta } from "/app/src/pages/parts/batteries/index.vue?macro=true";
import { default as _91id_939DRv2W1U4HMeta } from "/app/src/pages/parts/engines/[id].vue?macro=true";
import { default as createsJ1w8FmieDMeta } from "/app/src/pages/parts/engines/create.vue?macro=true";
import { default as index85V61jdpZJMeta } from "/app/src/pages/parts/engines/index.vue?macro=true";
import { default as _91id_93y81DRLYbbrMeta } from "/app/src/pages/parts/gas-engines/[id].vue?macro=true";
import { default as createknJNlU8gTmMeta } from "/app/src/pages/parts/gas-engines/create.vue?macro=true";
import { default as index13yagVMeiEMeta } from "/app/src/pages/parts/gas-engines/index.vue?macro=true";
import { default as _91id_93sF0T0XVmxFMeta } from "/app/src/pages/parts/heat-sources/[id].vue?macro=true";
import { default as createT0sQZo1Qc8Meta } from "/app/src/pages/parts/heat-sources/create.vue?macro=true";
import { default as index0LVHJ9bCGTMeta } from "/app/src/pages/parts/heat-sources/index.vue?macro=true";
import { default as indexCQIGbBANAyMeta } from "/app/src/pages/parts/index.vue?macro=true";
import { default as _91id_93Jsh9e4tINqMeta } from "/app/src/pages/parts/manufacturers/[id].vue?macro=true";
import { default as createlefebvSFPXMeta } from "/app/src/pages/parts/manufacturers/create.vue?macro=true";
import { default as indexwob2u9T7mYMeta } from "/app/src/pages/parts/manufacturers/index.vue?macro=true";
import { default as _91id_93RbZFuMYkGxMeta } from "/app/src/pages/parts/solar-panels/[id].vue?macro=true";
import { default as createRlPNPvmOlfMeta } from "/app/src/pages/parts/solar-panels/create.vue?macro=true";
import { default as indexaJYJHUeNcUMeta } from "/app/src/pages/parts/solar-panels/index.vue?macro=true";
import { default as _91id_934U79I2NstJMeta } from "/app/src/pages/parts/turbines/[id].vue?macro=true";
import { default as createqAs69EvJQuMeta } from "/app/src/pages/parts/turbines/create.vue?macro=true";
import { default as indextJqlwe8q3BMeta } from "/app/src/pages/parts/turbines/index.vue?macro=true";
import { default as partsnCloAic4ZwMeta } from "/app/src/pages/parts.vue?macro=true";
import { default as detailsHamTgGtKHBMeta } from "/app/src/pages/profile/details.vue?macro=true";
import { default as securityJazLZGuRUeMeta } from "/app/src/pages/profile/security.vue?macro=true";
import { default as profileTx35vCKuH1Meta } from "/app/src/pages/profile.vue?macro=true";
import { default as _91chartId_93l76tUk9EEVMeta } from "/app/src/pages/projects/[projectId]/dashboards/[dashboardId]/charts/[chartId].vue?macro=true";
import { default as createsjzAvkEng0Meta } from "/app/src/pages/projects/[projectId]/dashboards/[dashboardId]/charts/create.vue?macro=true";
import { default as editAJ5YUohDFsMeta } from "/app/src/pages/projects/[projectId]/dashboards/[dashboardId]/edit.vue?macro=true";
import { default as indexOAwZfTKqanMeta } from "/app/src/pages/projects/[projectId]/dashboards/[dashboardId]/index.vue?macro=true";
import { default as createHJd5NVg8ydMeta } from "/app/src/pages/projects/[projectId]/dashboards/create.vue?macro=true";
import { default as indexBnv7j4nCtjMeta } from "/app/src/pages/projects/[projectId]/dashboards/index.vue?macro=true";
import { default as detail_45viewaWDprD2JkMMeta } from "/app/src/pages/projects/[projectId]/detail-view.vue?macro=true";
import { default as detailsQrjon78zeeMeta } from "/app/src/pages/projects/[projectId]/details.vue?macro=true";
import { default as indexfWXIRTnMXYMeta } from "/app/src/pages/projects/[projectId]/external-users/index.vue?macro=true";
import { default as inviteBePJMK92e4Meta } from "/app/src/pages/projects/[projectId]/external-users/invite.vue?macro=true";
import { default as reportse9Vm9qDt2DMeta } from "/app/src/pages/projects/[projectId]/reports.vue?macro=true";
import { default as _91id_93LYR4rpgZofMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/aggregations/[id].vue?macro=true";
import { default as createYlArbL1gK7Meta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/aggregations/create.vue?macro=true";
import { default as indexx6HNfBMH1DMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/aggregations/index.vue?macro=true";
import { default as _91assetId_93dEyhPeCuSvMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/assets/[assetId].vue?macro=true";
import { default as createsVcsoOkWJUMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/assets/create.vue?macro=true";
import { default as indexLdwPmo5uk2Meta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/assets/index.vue?macro=true";
import { default as _91entryId_93YIfuT8Ktk4Meta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/electrical-demand/[entryId].vue?macro=true";
import { default as createADZ3Yg3JWKMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/electrical-demand/create.vue?macro=true";
import { default as indexDZKsTRqe0NMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/electrical-demand/index.vue?macro=true";
import { default as _91eventConditionId_93v1kUiTC5LRMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/event-conditions/[eventConditionId].vue?macro=true";
import { default as createBx6eCt0IrqMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/event-conditions/create.vue?macro=true";
import { default as _91id_93fHCFZRJAgFMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/finance/[id].vue?macro=true";
import { default as createLlz6WUp0svMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/finance/create.vue?macro=true";
import { default as indexiUS7a9Sv3uMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/finance/index.vue?macro=true";
import { default as optionsLtQu1hvIXzMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/finance/options.vue?macro=true";
import { default as forecastLG1bvehBqKMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/forecast.vue?macro=true";
import { default as headroom7pZoHx7q8nMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/headroom.vue?macro=true";
import { default as _91entryId_93bGSxZqLgk6Meta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/heat-demand/[entryId].vue?macro=true";
import { default as createZPtiiIf2kSMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/heat-demand/create.vue?macro=true";
import { default as indexAXRAnxMxOEMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/heat-demand/index.vue?macro=true";
import { default as index9hyyzXpVTsMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/index.vue?macro=true";
import { default as _91levelisedCostId_93vEIbibjBtrMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/levelised-costs/[levelisedCostId].vue?macro=true";
import { default as createsWs3Wo4xPfMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/levelised-costs/create.vue?macro=true";
import { default as indexZk51JUB4JiMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/levelised-costs/index.vue?macro=true";
import { default as module_45order4LHcADmw7nMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/module-order.vue?macro=true";
import { default as _91batteryBankId_93fLcnx9OQonMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/modules/batteries/[batteryBankId].vue?macro=true";
import { default as createRLM8og60pvMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/modules/batteries/create.vue?macro=true";
import { default as indexE710Fk9E9KMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/modules/batteries/index.vue?macro=true";
import { default as _91engineInstallId_93284qkhhLruMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/modules/engines/[engineInstallId].vue?macro=true";
import { default as createeGmiI9awmOMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/modules/engines/create.vue?macro=true";
import { default as indexrPf3SjPvrIMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/modules/engines/index.vue?macro=true";
import { default as _91gasEngineInstallId_93IqbkSXF4dmMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/modules/gas-engines/[gasEngineInstallId].vue?macro=true";
import { default as createzq8LYm8YsyMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/modules/gas-engines/create.vue?macro=true";
import { default as indexV5hwTzcM6fMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/modules/gas-engines/index.vue?macro=true";
import { default as indexSweTo5HW62Meta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/modules/headless/index.vue?macro=true";
import { default as _91heatSourceInstallId_93eogd70UwFOMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/modules/heat-sources/[heatSourceInstallId].vue?macro=true";
import { default as createFiKkA7sQyMMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/modules/heat-sources/create.vue?macro=true";
import { default as indexsredfW903uMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/modules/heat-sources/index.vue?macro=true";
import { default as _91hotwellId_93tdp2kRov0QMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/modules/hotwell/[hotwellId].vue?macro=true";
import { default as createvpvaqAttLbMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/modules/hotwell/create.vue?macro=true";
import { default as indexkXdIbiaLsaMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/modules/hotwell/index.vue?macro=true";
import { default as _91solarArrayId_93dXWa88z4knMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/modules/solar/[solarArrayId].vue?macro=true";
import { default as createErVQZeiAvJMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/modules/solar/create.vue?macro=true";
import { default as index3yoxSIu65aMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/modules/solar/index.vue?macro=true";
import { default as _91windInstallId_93SQtGFTRG43Meta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/modules/wind/[windInstallId].vue?macro=true";
import { default as createZr6ahU2A1zMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/modules/wind/create.vue?macro=true";
import { default as indexd8jwEdAaIwMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/modules/wind/index.vue?macro=true";
import { default as _91operationCriterionId_937oxe79NYK1Meta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/operation-criteria/[operationCriterionId].vue?macro=true";
import { default as create0zQqIw61TZMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/operation-criteria/create.vue?macro=true";
import { default as indexxtxWGeU6mQMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/operation-criteria/index.vue?macro=true";
import { default as _91id_93MCZLKsX3IkMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/profit-loss/[id].vue?macro=true";
import { default as createxm3q3wzfdIMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/profit-loss/create.vue?macro=true";
import { default as sensitivityewYIV1hIm3Meta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/sensitivity.vue?macro=true";
import { default as _91scenarioId_93E1QMu9QHehMeta } from "/app/src/pages/projects/[projectId]/scenarios/[scenarioId].vue?macro=true";
import { default as createm2JCF8NBjeMeta } from "/app/src/pages/projects/[projectId]/scenarios/create.vue?macro=true";
import { default as indexArJglrSuMgMeta } from "/app/src/pages/projects/[projectId]/scenarios/index.vue?macro=true";
import { default as value_45mapsogN5hojCT6Meta } from "/app/src/pages/projects/[projectId]/value-maps.vue?macro=true";
import { default as _91projectId_93SruFcGk1xOMeta } from "/app/src/pages/projects/[projectId].vue?macro=true";
import { default as createAmnvkgzS0kMeta } from "/app/src/pages/projects/create.vue?macro=true";
import { default as indexCBid7U1lDFMeta } from "/app/src/pages/projects/index.vue?macro=true";
import { default as projectsthHcGUblKAMeta } from "/app/src/pages/projects.vue?macro=true";
import { default as _91token_93Hiwsnq3ey4Meta } from "/app/src/pages/reset/[token].vue?macro=true";
import { default as component_45stubOCwWFhE0biMeta } from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@8.57.0_ioredis@5.4.1_magicast@0_7ba7xbmkapdyzlz2eo5s25cbs4/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubOCwWFhE0bi } from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@8.57.0_ioredis@5.4.1_magicast@0_7ba7xbmkapdyzlz2eo5s25cbs4/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93XOh4cis8EcMeta || {},
    component: () => import("/app/src/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "accept-terms",
    path: "/accept-terms",
    component: () => import("/app/src/pages/accept-terms.vue").then(m => m.default || m)
  },
  {
    name: adminKPGIlZeS47Meta?.name,
    path: "/admin",
    meta: adminKPGIlZeS47Meta || {},
    component: () => import("/app/src/pages/admin.vue").then(m => m.default || m),
    children: [
  {
    name: "admin-accounts-id",
    path: "accounts/:id()",
    component: () => import("/app/src/pages/admin/accounts/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-accounts-id-usage",
    path: "accounts/:id()/usage",
    component: () => import("/app/src/pages/admin/accounts/[id]/usage.vue").then(m => m.default || m)
  },
  {
    name: "admin-accounts-create",
    path: "accounts/create",
    component: () => import("/app/src/pages/admin/accounts/create.vue").then(m => m.default || m)
  },
  {
    name: "admin-accounts",
    path: "accounts",
    component: () => import("/app/src/pages/admin/accounts/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-announcements-id",
    path: "announcements/:id()",
    component: () => import("/app/src/pages/admin/announcements/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-announcements-create",
    path: "announcements/create",
    component: () => import("/app/src/pages/admin/announcements/create.vue").then(m => m.default || m)
  },
  {
    name: "admin-announcements",
    path: "announcements",
    component: () => import("/app/src/pages/admin/announcements/index.vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "",
    component: () => import("/app/src/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-licence-plans-id",
    path: "licence-plans/:id()",
    component: () => import("/app/src/pages/admin/licence-plans/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-licence-plans-create",
    path: "licence-plans/create",
    component: () => import("/app/src/pages/admin/licence-plans/create.vue").then(m => m.default || m)
  },
  {
    name: "admin-licence-plans",
    path: "licence-plans",
    component: () => import("/app/src/pages/admin/licence-plans/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-licences-id",
    path: "licences/:id()",
    component: () => import("/app/src/pages/admin/licences/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-licences-create",
    path: "licences/create",
    component: () => import("/app/src/pages/admin/licences/create.vue").then(m => m.default || m)
  },
  {
    name: "admin-licences",
    path: "licences",
    component: () => import("/app/src/pages/admin/licences/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-user-groups-id",
    path: "user-groups/:id()",
    component: () => import("/app/src/pages/admin/user-groups/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-user-groups-create",
    path: "user-groups/create",
    component: () => import("/app/src/pages/admin/user-groups/create.vue").then(m => m.default || m)
  },
  {
    name: "admin-user-groups",
    path: "user-groups",
    component: () => import("/app/src/pages/admin/user-groups/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-user-invites",
    path: "user-invites",
    component: () => import("/app/src/pages/admin/user-invites/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-user-invites-invite",
    path: "user-invites/invite",
    component: () => import("/app/src/pages/admin/user-invites/invite.vue").then(m => m.default || m)
  },
  {
    name: "admin-users-id",
    path: "users/:id()",
    component: () => import("/app/src/pages/admin/users/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-users-create",
    path: "users/create",
    component: () => import("/app/src/pages/admin/users/create.vue").then(m => m.default || m)
  },
  {
    name: "admin-users",
    path: "users",
    component: () => import("/app/src/pages/admin/users/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "external-invite-token",
    path: "/external-invite/:token()",
    meta: _91token_93OGRYcCTWyhMeta || {},
    component: () => import("/app/src/pages/external-invite/[token].vue").then(m => m.default || m)
  },
  {
    name: "forgot",
    path: "/forgot",
    meta: forgot0id9L0C55aMeta || {},
    component: () => import("/app/src/pages/forgot.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexdPwi6hPdMfMeta || {},
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invite-token",
    path: "/invite/:token()",
    meta: _91token_934IkuI9LT6NMeta || {},
    component: () => import("/app/src/pages/invite/[token].vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: login3hUYZUGxmUMeta || {},
    component: () => import("/app/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "mfa",
    path: "/mfa",
    meta: mfaldkDdPwrEVMeta || {},
    component: () => import("/app/src/pages/mfa.vue").then(m => m.default || m)
  },
  {
    name: partsnCloAic4ZwMeta?.name,
    path: "/parts",
    meta: partsnCloAic4ZwMeta || {},
    component: () => import("/app/src/pages/parts.vue").then(m => m.default || m),
    children: [
  {
    name: "parts-batteries-id",
    path: "batteries/:id()",
    component: () => import("/app/src/pages/parts/batteries/[id].vue").then(m => m.default || m)
  },
  {
    name: "parts-batteries-create",
    path: "batteries/create",
    component: () => import("/app/src/pages/parts/batteries/create.vue").then(m => m.default || m)
  },
  {
    name: "parts-batteries",
    path: "batteries",
    component: () => import("/app/src/pages/parts/batteries/index.vue").then(m => m.default || m)
  },
  {
    name: "parts-engines-id",
    path: "engines/:id()",
    component: () => import("/app/src/pages/parts/engines/[id].vue").then(m => m.default || m)
  },
  {
    name: "parts-engines-create",
    path: "engines/create",
    component: () => import("/app/src/pages/parts/engines/create.vue").then(m => m.default || m)
  },
  {
    name: "parts-engines",
    path: "engines",
    component: () => import("/app/src/pages/parts/engines/index.vue").then(m => m.default || m)
  },
  {
    name: "parts-gas-engines-id",
    path: "gas-engines/:id()",
    component: () => import("/app/src/pages/parts/gas-engines/[id].vue").then(m => m.default || m)
  },
  {
    name: "parts-gas-engines-create",
    path: "gas-engines/create",
    component: () => import("/app/src/pages/parts/gas-engines/create.vue").then(m => m.default || m)
  },
  {
    name: "parts-gas-engines",
    path: "gas-engines",
    component: () => import("/app/src/pages/parts/gas-engines/index.vue").then(m => m.default || m)
  },
  {
    name: "parts-heat-sources-id",
    path: "heat-sources/:id()",
    component: () => import("/app/src/pages/parts/heat-sources/[id].vue").then(m => m.default || m)
  },
  {
    name: "parts-heat-sources-create",
    path: "heat-sources/create",
    component: () => import("/app/src/pages/parts/heat-sources/create.vue").then(m => m.default || m)
  },
  {
    name: "parts-heat-sources",
    path: "heat-sources",
    component: () => import("/app/src/pages/parts/heat-sources/index.vue").then(m => m.default || m)
  },
  {
    name: "parts",
    path: "",
    component: () => import("/app/src/pages/parts/index.vue").then(m => m.default || m)
  },
  {
    name: "parts-manufacturers-id",
    path: "manufacturers/:id()",
    component: () => import("/app/src/pages/parts/manufacturers/[id].vue").then(m => m.default || m)
  },
  {
    name: "parts-manufacturers-create",
    path: "manufacturers/create",
    component: () => import("/app/src/pages/parts/manufacturers/create.vue").then(m => m.default || m)
  },
  {
    name: "parts-manufacturers",
    path: "manufacturers",
    component: () => import("/app/src/pages/parts/manufacturers/index.vue").then(m => m.default || m)
  },
  {
    name: "parts-solar-panels-id",
    path: "solar-panels/:id()",
    component: () => import("/app/src/pages/parts/solar-panels/[id].vue").then(m => m.default || m)
  },
  {
    name: "parts-solar-panels-create",
    path: "solar-panels/create",
    component: () => import("/app/src/pages/parts/solar-panels/create.vue").then(m => m.default || m)
  },
  {
    name: "parts-solar-panels",
    path: "solar-panels",
    component: () => import("/app/src/pages/parts/solar-panels/index.vue").then(m => m.default || m)
  },
  {
    name: "parts-turbines-id",
    path: "turbines/:id()",
    component: () => import("/app/src/pages/parts/turbines/[id].vue").then(m => m.default || m)
  },
  {
    name: "parts-turbines-create",
    path: "turbines/create",
    component: () => import("/app/src/pages/parts/turbines/create.vue").then(m => m.default || m)
  },
  {
    name: "parts-turbines",
    path: "turbines",
    component: () => import("/app/src/pages/parts/turbines/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "profile",
    path: "/profile",
    meta: profileTx35vCKuH1Meta || {},
    component: () => import("/app/src/pages/profile.vue").then(m => m.default || m),
    children: [
  {
    name: "profile-details",
    path: "details",
    component: () => import("/app/src/pages/profile/details.vue").then(m => m.default || m)
  },
  {
    name: "profile-security",
    path: "security",
    component: () => import("/app/src/pages/profile/security.vue").then(m => m.default || m)
  }
]
  },
  {
    name: projectsthHcGUblKAMeta?.name,
    path: "/projects",
    meta: projectsthHcGUblKAMeta || {},
    component: () => import("/app/src/pages/projects.vue").then(m => m.default || m),
    children: [
  {
    name: "projects-projectId",
    path: ":projectId()",
    meta: _91projectId_93SruFcGk1xOMeta || {},
    component: () => import("/app/src/pages/projects/[projectId].vue").then(m => m.default || m),
    children: [
  {
    name: "projects-projectId-dashboards-dashboardId-charts-chartId",
    path: "dashboards/:dashboardId()/charts/:chartId()",
    component: () => import("/app/src/pages/projects/[projectId]/dashboards/[dashboardId]/charts/[chartId].vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-dashboards-dashboardId-charts-create",
    path: "dashboards/:dashboardId()/charts/create",
    component: () => import("/app/src/pages/projects/[projectId]/dashboards/[dashboardId]/charts/create.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-dashboards-dashboardId-edit",
    path: "dashboards/:dashboardId()/edit",
    component: () => import("/app/src/pages/projects/[projectId]/dashboards/[dashboardId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-dashboards-dashboardId",
    path: "dashboards/:dashboardId()",
    component: () => import("/app/src/pages/projects/[projectId]/dashboards/[dashboardId]/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-dashboards-create",
    path: "dashboards/create",
    component: () => import("/app/src/pages/projects/[projectId]/dashboards/create.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-dashboards",
    path: "dashboards",
    component: () => import("/app/src/pages/projects/[projectId]/dashboards/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-detail-view",
    path: "detail-view",
    component: () => import("/app/src/pages/projects/[projectId]/detail-view.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-details",
    path: "details",
    component: () => import("/app/src/pages/projects/[projectId]/details.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-external-users",
    path: "external-users",
    component: () => import("/app/src/pages/projects/[projectId]/external-users/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-external-users-invite",
    path: "external-users/invite",
    meta: inviteBePJMK92e4Meta || {},
    component: () => import("/app/src/pages/projects/[projectId]/external-users/invite.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-reports",
    path: "reports",
    component: () => import("/app/src/pages/projects/[projectId]/reports.vue").then(m => m.default || m)
  },
  {
    name: _91scenarioId_93E1QMu9QHehMeta?.name,
    path: "scenarios/:scenarioId()",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId].vue").then(m => m.default || m),
    children: [
  {
    name: "projects-projectId-scenarios-scenarioId-aggregations-id",
    path: "aggregations/:id()",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/aggregations/[id].vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-aggregations-create",
    path: "aggregations/create",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/aggregations/create.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-aggregations",
    path: "aggregations",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/aggregations/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-assets-assetId",
    path: "assets/:assetId()",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/assets/[assetId].vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-assets-create",
    path: "assets/create",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/assets/create.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-assets",
    path: "assets",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/assets/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-electrical-demand-entryId",
    path: "electrical-demand/:entryId()",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/electrical-demand/[entryId].vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-electrical-demand-create",
    path: "electrical-demand/create",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/electrical-demand/create.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-electrical-demand",
    path: "electrical-demand",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/electrical-demand/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-event-conditions-eventConditionId",
    path: "event-conditions/:eventConditionId()",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/event-conditions/[eventConditionId].vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-event-conditions-create",
    path: "event-conditions/create",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/event-conditions/create.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-finance-id",
    path: "finance/:id()",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/finance/[id].vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-finance-create",
    path: "finance/create",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/finance/create.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-finance",
    path: "finance",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/finance/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-finance-options",
    path: "finance/options",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/finance/options.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-forecast",
    path: "forecast",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/forecast.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-headroom",
    path: "headroom",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/headroom.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-heat-demand-entryId",
    path: "heat-demand/:entryId()",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/heat-demand/[entryId].vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-heat-demand-create",
    path: "heat-demand/create",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/heat-demand/create.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-heat-demand",
    path: "heat-demand",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/heat-demand/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId",
    path: "",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-levelised-costs-levelisedCostId",
    path: "levelised-costs/:levelisedCostId()",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/levelised-costs/[levelisedCostId].vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-levelised-costs-create",
    path: "levelised-costs/create",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/levelised-costs/create.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-levelised-costs",
    path: "levelised-costs",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/levelised-costs/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-module-order",
    path: "module-order",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/module-order.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-modules-batteries-batteryBankId",
    path: "modules/batteries/:batteryBankId()",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/modules/batteries/[batteryBankId].vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-modules-batteries-create",
    path: "modules/batteries/create",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/modules/batteries/create.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-modules-batteries",
    path: "modules/batteries",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/modules/batteries/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-modules-engines-engineInstallId",
    path: "modules/engines/:engineInstallId()",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/modules/engines/[engineInstallId].vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-modules-engines-create",
    path: "modules/engines/create",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/modules/engines/create.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-modules-engines",
    path: "modules/engines",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/modules/engines/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-modules-gas-engines-gasEngineInstallId",
    path: "modules/gas-engines/:gasEngineInstallId()",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/modules/gas-engines/[gasEngineInstallId].vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-modules-gas-engines-create",
    path: "modules/gas-engines/create",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/modules/gas-engines/create.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-modules-gas-engines",
    path: "modules/gas-engines",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/modules/gas-engines/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-modules-headless",
    path: "modules/headless",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/modules/headless/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-modules-heat-sources-heatSourceInstallId",
    path: "modules/heat-sources/:heatSourceInstallId()",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/modules/heat-sources/[heatSourceInstallId].vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-modules-heat-sources-create",
    path: "modules/heat-sources/create",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/modules/heat-sources/create.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-modules-heat-sources",
    path: "modules/heat-sources",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/modules/heat-sources/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-modules-hotwell-hotwellId",
    path: "modules/hotwell/:hotwellId()",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/modules/hotwell/[hotwellId].vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-modules-hotwell-create",
    path: "modules/hotwell/create",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/modules/hotwell/create.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-modules-hotwell",
    path: "modules/hotwell",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/modules/hotwell/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-modules-solar-solarArrayId",
    path: "modules/solar/:solarArrayId()",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/modules/solar/[solarArrayId].vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-modules-solar-create",
    path: "modules/solar/create",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/modules/solar/create.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-modules-solar",
    path: "modules/solar",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/modules/solar/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-modules-wind-windInstallId",
    path: "modules/wind/:windInstallId()",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/modules/wind/[windInstallId].vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-modules-wind-create",
    path: "modules/wind/create",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/modules/wind/create.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-modules-wind",
    path: "modules/wind",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/modules/wind/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-operation-criteria-operationCriterionId",
    path: "operation-criteria/:operationCriterionId()",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/operation-criteria/[operationCriterionId].vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-operation-criteria-create",
    path: "operation-criteria/create",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/operation-criteria/create.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-operation-criteria",
    path: "operation-criteria",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/operation-criteria/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-profit-loss-id",
    path: "profit-loss/:id()",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/profit-loss/[id].vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-profit-loss-create",
    path: "profit-loss/create",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/profit-loss/create.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios-scenarioId-sensitivity",
    path: "sensitivity",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/[scenarioId]/sensitivity.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "projects-projectId-scenarios-create",
    path: "scenarios/create",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/create.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-scenarios",
    path: "scenarios",
    component: () => import("/app/src/pages/projects/[projectId]/scenarios/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-value-maps",
    path: "value-maps",
    component: () => import("/app/src/pages/projects/[projectId]/value-maps.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "projects-create",
    path: "create",
    meta: createAmnvkgzS0kMeta || {},
    component: () => import("/app/src/pages/projects/create.vue").then(m => m.default || m)
  },
  {
    name: "projects",
    path: "",
    meta: indexCBid7U1lDFMeta || {},
    component: () => import("/app/src/pages/projects/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "reset-token",
    path: "/reset/:token()",
    meta: _91token_93Hiwsnq3ey4Meta || {},
    component: () => import("/app/src/pages/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: component_45stubOCwWFhE0biMeta?.name,
    path: "/.well-known/change-password",
    component: component_45stubOCwWFhE0bi
  },
  {
    name: component_45stubOCwWFhE0biMeta?.name,
    path: "/.well-known/security.txt",
    component: component_45stubOCwWFhE0bi
  }
]