import type { ButtonVariant } from "~/types/props"

import { defineStore } from "pinia"

import { ButtonVariants } from "~/types/props"

interface Button {
  text: string
  type: ButtonVariant
  onClick: () => void | Promise<void>
}

interface ConfirmationModalProps {
  icon?: {
    name: string
    class: string
  }
  title: string
  message?: string
  buttons: Button[]
  onClose?: () => void | Promise<void>
}

interface ModalState extends ConfirmationModalProps {
  isOpen: boolean
}

const state: ModalState = {
  title: "Empty title",
  isOpen: false,
  buttons: [],
  message: undefined,
}

interface ModalIcon {
  name: string
  class: string
}

export const modalIcons: Record<string, ModalIcon> = {
  warning: {
    name: "material-symbols:warning",
    class: "text-red-600",
  },
  success: {
    name: "material-symbols:check-circle",
    class: "text-forest-700",
  },
}

export const useModalStore = defineStore("modals", {
  state: () => state,
  actions: {
    openModal({
      title,
      message,
      buttons,
      icon,
      onClose,
    }: ConfirmationModalProps) {
      this.isOpen = true
      this.title = title
      this.icon = icon
      this.message = message ?? ""
      this.buttons = buttons ?? []
      this.onClose = onClose
    },
    close() {
      this.isOpen = false
      this.onClose?.()
    },
  },
  getters: {
    modal: (state) => state,
  },
})

export function modalAlert(title: string, icon?: ModalIcon, message?: string) {
  const modal = useModalStore()
  return new Promise((resolve) => {
    modal.openModal({
      title,
      message,
      icon,
      buttons: [
        {
          type: ButtonVariants.PRIMARY,
          text: "OK",
          onClick: () => {
            modal.close()
            resolve(null)
          },
        },
      ],
      onClose: () => resolve(null),
    })
  })
}
