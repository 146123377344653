import revive_payload_client_CnSzSpp3vU from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@8.57.0_ioredis@5.4.1_magicast@0_7ba7xbmkapdyzlz2eo5s25cbs4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_hjrGqmYwIN from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@8.57.0_ioredis@5.4.1_magicast@0_7ba7xbmkapdyzlz2eo5s25cbs4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_pzPdfbq92f from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@8.57.0_ioredis@5.4.1_magicast@0_7ba7xbmkapdyzlz2eo5s25cbs4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import sentry_client_s8K3gq1edP from "/app/node_modules/.pnpm/@binaryoverload+nuxt-sentry@0.4.0_magicast@0.3.4_rollup@4.19.0_vue@3.4.38_typescript@5.5.3_/node_modules/@binaryoverload/nuxt-sentry/dist/runtime/sentry.client.mjs";
import payload_client_42xluNTnED from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@8.57.0_ioredis@5.4.1_magicast@0_7ba7xbmkapdyzlz2eo5s25cbs4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_pochMEAGRv from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@8.57.0_ioredis@5.4.1_magicast@0_7ba7xbmkapdyzlz2eo5s25cbs4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_KQvuo2nvmL from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@8.57.0_ioredis@5.4.1_magicast@0_7ba7xbmkapdyzlz2eo5s25cbs4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_6PoyX4l2mN from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@8.57.0_ioredis@5.4.1_magicast@0_7ba7xbmkapdyzlz2eo5s25cbs4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_lWDxtgXHV6 from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.2_magicast@0.3.4_rollup@4.19.0_typescript@5.5.3_vue@3.4.38_typescript@5.5.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_JvvpdPHyLB from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@8.57.0_ioredis@5.4.1_magicast@0_7ba7xbmkapdyzlz2eo5s25cbs4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_r1df71aIBi from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_magicast@0.3.4_rollup@4.19.0_vue@3.4.38_typescript@5.5.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_jVdgdQ0q1F from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_magicast@0.3.4_rollup@4.19.0_vue@3.4.38_typescript@5.5.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_MQ7NwvAtCE from "/app/node_modules/.pnpm/@nuxt+icon@1.4.7_magicast@0.3.4_rollup@4.19.0_vite@5.3.4_@types+node@20.14.11_sass@1.77.8_ter_7weotimxdbinujc6bndeyndcfa/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_DOcNkRvfbx from "/app/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.2_magicast@0.3.4_rollup@4.19.0_typesc_cvmpleztjqimdmo4qo65tdcgtm/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import chart_client_itH0xDmBxD from "/app/src/plugins/chart.client.ts";
import event_bus_2B8uO4OVrn from "/app/src/plugins/event-bus.ts";
import nanoid_XOie5CrMEc from "/app/src/plugins/nanoid.ts";
import ofetch_adqO16FtiU from "/app/src/plugins/ofetch.ts";
import popper_uNmHSnnDKH from "/app/src/plugins/popper.ts";
import short_uuid_LeqKE96ZEI from "/app/src/plugins/short-uuid.ts";
import tanstack_query_AjE6EeU5nP from "/app/src/plugins/tanstack-query.ts";
export default [
  revive_payload_client_CnSzSpp3vU,
  unhead_hjrGqmYwIN,
  router_pzPdfbq92f,
  sentry_client_s8K3gq1edP,
  payload_client_42xluNTnED,
  navigation_repaint_client_pochMEAGRv,
  check_outdated_build_client_KQvuo2nvmL,
  chunk_reload_client_6PoyX4l2mN,
  plugin_vue3_lWDxtgXHV6,
  components_plugin_KR1HBZs4kY,
  prefetch_client_JvvpdPHyLB,
  switch_locale_path_ssr_r1df71aIBi,
  i18n_jVdgdQ0q1F,
  plugin_MQ7NwvAtCE,
  plugin_DOcNkRvfbx,
  chart_client_itH0xDmBxD,
  event_bus_2B8uO4OVrn,
  nanoid_XOie5CrMEc,
  ofetch_adqO16FtiU,
  popper_uNmHSnnDKH,
  short_uuid_LeqKE96ZEI,
  tanstack_query_AjE6EeU5nP
]