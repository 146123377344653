import get from "lodash/get"
import isArray from "lodash/isArray"
import isPlainObject from "lodash/isPlainObject"
import mapValues from "lodash/mapValues"

export function coerceEmptyUndefined<T extends string | number>(
  value: T | undefined,
): T | undefined {
  if (typeof value === "string") {
    if (value.trim().length === 0) {
      return undefined
    }
  }

  if (typeof value === "number") {
    if (value === 0) {
      return undefined
    }
  }

  return value
}

export function isEmptyOrUndefined<T extends string | number>(
  value: T | undefined,
): boolean {
  return coerceEmptyUndefined(value) === undefined
}

export function isValidNumber(
  value: number | null | undefined,
): value is number {
  return value != null && typeof value === "number" && !isNaN(value)
}

export function keyOrGetter<TType, TOutput = any>(
  input: string | ((value: TType) => TOutput) | undefined,
  value: TType,
): TOutput | undefined {
  if (input == null) return

  return typeof input === "function"
    ? input(value)
    : (get<TType, string>(value, input) as TOutput)
}

export function nullToUndefined(value: any): any {
  if (isPlainObject(value)) {
    return mapValues(value, nullToUndefined)
  }
  if (isArray(value)) {
    return value.map(nullToUndefined)
  }
  if (value === null) {
    return undefined
  }
  return value
}
