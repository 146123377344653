import { skipToken } from "@tanstack/vue-query"

import { usersQueryKey } from "~/api/queryKeys"
import { getUserSelf } from "~/api/requests/users"

export function useUserData() {
  const authStore = useAuthStore()

  const { data: user } = useDeimosQueryNoSuspense({
    queryKey: [usersQueryKey, "@me"],
    queryFn: computed(() =>
      authStore.token ? () => getUserSelf() : skipToken,
    ),
  })

  const userDisplayName = computed(() => {
    if (!user.value) return "Not logged in"
    return getDisplayName(user.value)
  })

  return { isLoggedIn: authStore.isLoggedIn, user, userDisplayName }
}
