<script setup lang="ts">
import { tv } from "tailwind-variants"

const props = defineProps<{ to: string; icon: string }>()

const route = useRoute()

const active = computed(() => {
  const pathTrimmed = route.path.replace(/\/$/, "")
  const toTrimmed = props.to.replace(/\/$/, "")

  if (pathTrimmed === toTrimmed) {
    return true
  }

  return toTrimmed !== "" && pathTrimmed.startsWith(toTrimmed)
})

const classes = tv({
  base: "box-border text-ebony-200 px-4 py-1 flex items-center gap-2 transition-colors duration-200 hover:text-ebony-100 hover:bg-ebony-400 rounded-full bg-opacity-25 hover:bg-opacity-25",
  variants: {
    active: {
      true: "text-forest-200 bg-forest-600 hover:bg-forest-400 hover:text-forest-100",
    },
  },
})
</script>

<template>
  <NuxtLink :to="props.to" :class="classes({ active })">
    <Icon :name="icon" />
    <slot />
  </NuxtLink>
</template>
