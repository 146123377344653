<script setup lang="ts">
import { tv } from "tailwind-variants"

const props = defineProps<{
  variant: "light" | "dark"
}>()

const classes = tv(
  {
    slots: {
      text: "mx-auto flex gap-1 flex-col w-full select-none items-center justify-center pt-2 pb-4 text-sm",
      link: "",
    },
    variants: {
      variant: {
        light: {
          text: "text-dusk-500",
        },
        dark: {
          text: "text-ebony-200",
          link: "text-forest-400",
        },
      },
    },
  },
  tvConfig,
)

const slots = computed(() => classes({ variant: props.variant }))

const { version } = useAppConfig()

const {
  public: { environment },
} = useRuntimeConfig()
const isDev = import.meta.dev
</script>

<template>
  <div :class="slots.text()">
    <Container class="flex flex-col text-center px-8 justify-center">
      <p class="mb-2">
        Copyright &copy; {{ new Date().getFullYear() }} Deimos by Middlegate
        Software, a limited company registered in England and Wales (Company No.
        15162412) &mdash; All Rights Reserved
      </p>

      <div class="space-x-2">
        <InlineLink to="/privacy">Privacy Policy</InlineLink>
        <span>&mdash;</span>
        <InlineLink to="/terms-of-use">Terms of Use</InlineLink>
        <span>&mdash;</span>
        <InlineLink
          to="https://middlegatesoftware.slite.page/p/tnwmI6SNzeRTVb/Deimos-Handbook"
          target="_blank"
          >Help</InlineLink
        >

        <span>&mdash;</span>
        <span class="select-all"
          >API <BackendVersion /> / UI {{ version
          }}<span v-if="environment === 'staging'">
            / {{ isDev ? "Dev" : "Staging" }}</span
          >
        </span>
      </div>
    </Container>
  </div>
</template>
