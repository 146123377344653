<script setup lang="ts">
const { $ofetch } = useNuxtApp()

const { data, isLoading } = await useDeimosQuery({
  queryKey: ["backendVersion"],
  queryFn: () =>
    $ofetch<{ version: string }>("/meta").then((response) => {
      // Take the first three parts of the version string
      return response.version.split(".").slice(0, 3).join(".")
    }),
  staleTime: Infinity,
})
</script>

<template>
  <span v-if="isLoading">...</span>
  <span v-else-if="data">v{{ data }}</span>
  <span v-else>Unknown</span>
</template>
