<script setup lang="ts">
import type { provideUseId } from "@headlessui/vue"

const provideId: typeof provideUseId = provideHeadlessUseId
provideId(() => useId())
</script>

<template>
  <div id="__app">
    <NuxtLayout>
      <NuxtPage class="h-full" />
    </NuxtLayout>
  </div>
</template>
