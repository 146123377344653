import type { FetchError } from "ofetch"
import type { RouteComponent, RouteLocationNormalized } from "vue-router"

import { defineNuxtRouteMiddleware } from "#app"
import { useAccountStore } from "~/stores/account"
import { useAuthStore } from "~/stores/auth"
import { isLicenceActive } from "~/utils/permissions"
import { normalizePath } from "~/utils/routing"

export default defineNuxtRouteMiddleware(async (to) => {
  const nuxtApp = useNuxtApp()
  if (
    import.meta.client &&
    nuxtApp.isHydrating &&
    nuxtApp.payload.serverRendered
  ) {
    return
  }

  const authStore = useAuthStore()
  const accountStore = useAccountStore()

  if (authStore.token) {
    try {
      await authStore.fetchUser()
      await accountStore.fetchUserAccount()
    } catch (e: any) {
      if (e.name === "FetchError") {
        const fetchError = e as FetchError

        if (fetchError.statusCode === 401) {
          authStore.resetAuth()
        } else {
          throw e
        }
      } else {
        throw e
      }
    }
  }

  if (to.meta.auth === false) {
    return
  }

  const matches: unknown[] = []
  const Components = getMatchedComponents(to, matches)

  if (Components.length === 0) {
    return
  }

  const pageIsInGuestMode = to.meta.auth === "guest"

  const nextPage = to.query.next?.toString()

  const insidePage = (page: string) =>
    normalizePath(to.path) === normalizePath(page)

  if (authStore.isLoggedIn && authStore.user) {
    if (to.meta.requiresLicence && authStore.user.accountId != null) {
      const currentAccount = accountStore.currentAccount

      if (!currentAccount?.licences?.some(isLicenceActive)) {
        throw createError({
          statusCode: 403,
          stack: undefined,
          message: "licence",
        })
      }
    }
    if (!insidePage("/mfa") && !authStore.mfaVerified) {
      return navigateTo(`/mfa?next=${encodeURIComponent(to.fullPath)}`)
    }

    if (insidePage("/mfa")) {
      if (authStore.mfaVerified) return navigateTo(nextPage ?? "/")
      return
    }

    const termsOfUseDate = useRuntimeConfig().public.lastUpdatedTermsOfUse

    const userMustAcceptTerms =
      authStore.user.acceptedTermsAt == null ||
      (termsOfUseDate != "" && authStore.user.acceptedTermsAt < termsOfUseDate)

    if (!insidePage("/accept-terms") && userMustAcceptTerms) {
      return navigateTo(`/accept-terms?next=${encodeURIComponent(to.fullPath)}`)
    }

    if (insidePage("/accept-terms")) {
      if (!userMustAcceptTerms) return navigateTo(nextPage ?? "/")
      return
    }

    if (insidePage("/login") || pageIsInGuestMode) {
      return navigateTo(nextPage ?? "/")
    }
  } else if (!pageIsInGuestMode) {
    return navigateTo(`/login?next=${encodeURIComponent(to.fullPath)}`)
  }
})

export function getMatchedComponents(
  route: RouteLocationNormalized,
  matches: unknown[] = [],
): RouteComponent[][] {
  return [
    ...route.matched.map(function (m, index: number) {
      const components = m.components
      if (components == null) return []
      return Object.keys(components).map(function (key) {
        matches.push(index)
        // We can assert the key's value exists because it comes from Object.keys
        return components[key]!
      })
    }),
  ]
}
