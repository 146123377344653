<script setup lang="ts">
import { useAuthStore } from "~/stores/auth"
import { permissionTypes } from "~/types/permissions"

const auth = useAuthStore()

const partsTypes = [
  permissionTypes.Battery,
  permissionTypes.Engine,
  permissionTypes.HeatSource,
  permissionTypes.Manufacturer,
  permissionTypes.SolarPanel,
  permissionTypes.WindTurbine,
]
</script>

<template>
  <Container class="select-none py-4 hidden lg:block">
    <div class="mx-auto flex items-center justify-between">
      <div class="flex items-center space-x-5">
        <NuxtLink
          to="/"
          class="mr-10 font-heading text-wordmark font-bold text-white">
          Deimos
        </NuxtLink>
        <NavBarLink v-if="auth.isLoggedIn" to="/" icon="mingcute:home-3-fill">
          Home
        </NavBarLink>
        <NavBarLink
          v-if="auth.isLoggedIn"
          to="/projects"
          icon="mingcute:drawer-2-fill">
          Projects
        </NavBarLink>
        <NavBarLink
          v-if="hasPartialGlobalPermission(partsTypes, ['Read'])"
          to="/parts"
          icon="mingcute:tool-fill">
          Parts
        </NavBarLink>
        <NavBarLink
          v-if="
            hasPartialGlobalPermission(
              ['Account'],
              ['Create', 'Update', 'Delete'],
            ) ||
            hasPartialAccountPermission(
              ['ExternalUser', 'User', 'UserGroup'],
              ['Read'],
            )
          "
          to="/admin"
          icon="mingcute:safe-shield-2-fill">
          Admin
        </NavBarLink>
      </div>

      <NavBarDropdown v-if="auth.isLoggedIn" />
      <Button
        v-else
        variant="primary"
        to="/login"
        icon="mingcute:enter-door-fill">
        Login
      </Button>
    </div>
  </Container>
</template>
