import type { createTV } from "tailwind-variants"

import { getDefaultConfig, mergeConfigs, validators } from "tailwind-merge"

type TVConfig = Parameters<typeof createTV>["0"]

export const tvConfig: TVConfig = {
  twMergeConfig: mergeConfigs(getDefaultConfig(), {
    override: {
      classGroups: {
        "font-size": [
          {
            text: [
              "base",
              "hero",
              "wordmark",
              "section",
              "part",
              "sm",
              validators.isArbitraryLength,
            ],
          },
        ],
      },
    },
  }),
}
