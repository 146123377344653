export function unionArrays<T>(...arrays: T[][]): T[] {
  const set = new Set<T>()

  for (const arr of arrays) {
    for (const item of arr) {
      set.add(item)
    }
  }

  return Array.from(set)
}

export function subtract2DArrays(array1: number[][], array2: number[][]) {
  const result = [] as number[][]

  if (array1.length !== array2.length)
    throw new Error("Arrays must be the same length")

  for (let i = 0; i < array1.length; i++) {
    const newArray = [...array1[i]!]

    for (let j = 0; j < newArray.length; j++) {
      // Null assertion is safe because j is always within bounds
      newArray[j] = newArray[j]! - (array2[i]?.[j] ?? 0)
    }

    result.push(newArray)
  }

  return result
}
