<script setup lang="ts">
import type { ButtonVariant } from "~/types/props"

import { tv } from "tailwind-variants"

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(
  defineProps<{
    to?: string
    variant: ButtonVariant
    type?: "button" | "submit"
    icon?: string
    iconClass?: string | string[]
    loading?: boolean
    disabled?: boolean
    class?: string
    wrapperClass?: string
    tooltip?: string
    size?: "small" | "large"
  }>(),
  {
    type: "button",
    icon: undefined,
    to: undefined,
    loading: false,
    disabled: false,
    class: undefined,
    iconClass: "",
    tooltip: undefined,
    wrapperClass: undefined,
    size: "large",
  },
)

const componentSlots = useSlots()

const classes = tv({
  variants: {
    disabled: {
      true: {
        button: "cursor-not-allowed opacity-60",
      },
    },
    loading: {
      true: {
        button: "cursor-wait",
      },
    },
    variant: {
      primary: {
        button:
          "bg-forest-700 hover:bg-forest-800 focus-visible:ring-forest-800",
      },
      secondary: {
        button:
          "bg-dusk-100 text-ebony-800 hover:bg-dusk-200 focus-visible:ring-dusk-500 ",
        spinner: "bg-ebony-900",
      },
      tertiary: {
        button:
          "border border-dusk-200 text-dusk-500 hover:border-dusk-300 hover:text-ebony-700",
        spinner: "bg-ebony-900",
      },
      danger: {
        button: "bg-red-600 bg-opacity-25 text-red-600 hover:bg-opacity-50",
        spinner: "bg-red-600",
      },
    },
    size: {
      small: {
        button: "px-4 py-2.5 text-base",
      },
      large: {},
    },
    hasContent: {
      true: {
        icon: "-ml-1",
      },
      false: {
        icon: "text-[20px]",
      },
    },
  },
  compoundVariants: [
    {
      size: "large",
      hasContent: true,
      class: {
        button: "px-10 py-3",
      },
    },
    {
      size: "large",
      hasContent: false,
      class: {
        button: "px-[6px] py-[6px] text-base",
      },
    },
  ],
  slots: {
    spinner: "bg-white",
    icon: "text-[24px]",
    button:
      "pointer-events-auto box-border inline w-fit h-fit whitespace-nowrap cursor-pointer text-white select-none ring-offset-1 active:enabled:transform transition active:enabled:scale-105 rounded-lg py-3 font-bold shadow-sm focus-visible:outline-none focus-visible:ring-2",
  },
})

const hasContent = computed(() => componentSlots.default != null)

const slots = computed(() => {
  return classes({
    variant: props.variant,
    disabled: props.disabled,
    loading: props.loading,
    hasContent: hasContent.value,
    size: props.size,
  })
})
</script>

<template>
  <div class="inline-block" :class="props.wrapperClass">
    <Popper
      class="h-full w-full text-[16px] font-normal pointer-events-none [&>div:first-of-type]:h-full [&>div:first-of-type]:w-full items-stretch justify-stretch"
      hover
      offset-distance="0"
      arrow>
      <NuxtLink
        v-if="props.to && !disabled && !loading"
        :to="props.to"
        v-bind="$attrs">
        <button
          :class="slots.button({ class: props.class })"
          :disabled="disabled || loading"
          :type="type">
          <LoadingDots v-if="loading" :dot-class="slots.spinner()" />
          <span v-else class="flex items-center justify-center gap-2">
            <Icon
              v-if="icon"
              :name="icon"
              :class="slots.icon({ class: iconClass })" />
            <span v-if="hasContent"><slot /></span>
          </span>
        </button>
      </NuxtLink>
      <button
        v-else
        :class="slots.button({ class: props.class })"
        :disabled="disabled || loading"
        :type="type"
        v-bind="$attrs">
        <LoadingDots v-if="loading" :dot-class="slots.spinner()" />
        <span v-else class="flex items-center justify-center gap-2">
          <Icon
            v-if="icon"
            :name="icon"
            :class="slots.icon({ class: iconClass })" />
          <span v-if="hasContent">
            <slot />
          </span>
        </span>
      </button>
      <template v-if="tooltip" #content>
        {{ tooltip }}
      </template>
    </Popper>
  </div>
</template>
