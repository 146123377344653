<script setup lang="ts">
import { useUserData } from "~/composables/useUserData"
import { useAccountStore } from "~/stores/account"
import { useAuthStore } from "~/stores/auth"

const auth = useAuthStore()
const accounts = useAccountStore()
const config = useRuntimeConfig().public

const { userDisplayName } = useUserData()

const currentAccountName = computed(() => {
  return accounts.userAccount?.name
})
</script>

<template>
  <HeadlessMenu as="div" class="relative inline-block text-left">
    <HeadlessMenuButton
      class="hover:border-teal-500 hover:bg-teal-50 hover:text-teal-700 focus-visible:ring-gray-500 flex w-52 items-center rounded-full bg-ebony-600 bg-opacity-50 px-4 py-2 transition-colors hover:bg-ebony-500 focus:outline-none focus-visible:ring-2">
      <Icon class="text-[24px] text-ebony-200" name="mingcute:user-3-fill" />
      <div class="ml-2 flex flex-col items-start">
        <p class="text-base/4 text-white">{{ userDisplayName }}</p>
        <p v-if="currentAccountName" class="text-sm/[12px] text-forest-200">
          {{ currentAccountName }}
        </p>
      </div>
      <Icon
        class="ml-auto text-[24px] text-ebony-200"
        name="mingcute:down-small-fill" />
    </HeadlessMenuButton>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95">
      <HeadlessMenuItems
        class="absolute right-0 z-10 mt-2 w-64 origin-top-right rounded-lg bg-ebony-700 px-3 py-2 text-ebony-200 focus:outline-none">
        <NavBarDropdownItem link="/profile" icon="mingcute:settings-2-fill">
          Profile and Settings
        </NavBarDropdownItem>
        <NavBarDropdownItem
          link="https://middlegatesoftware.slite.page/p/tnwmI6SNzeRTVb/Deimos-Handbook"
          target="_blank"
          icon="mingcute:question-fill">
          Help
        </NavBarDropdownItem>
        <NavBarDropdownItem link="/changelog" icon="mingcute:document-2-fill">
          Changelog
        </NavBarDropdownItem>
        <NavBarDropdownItem
          v-if="config.bugReportUrl?.length != 0"
          target="_blank"
          icon="mingcute:bug-fill"
          :link="config.bugReportUrl">
          Report a Bug
        </NavBarDropdownItem>
        <HorizontalDivider class="-mx-3" />
        <NavBarDropdownItem
          icon="mingcute:exit-door-fill"
          class="text-red-600"
          @click="auth.logout()">
          Logout
        </NavBarDropdownItem>
      </HeadlessMenuItems>
    </transition>
  </HeadlessMenu>
</template>
