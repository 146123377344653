import type { ReadonlyTuple } from "type-fest"
import type {
  ExternalUser,
  Licence,
  PermissionRoleToCreate,
  Project,
  User,
} from "~/types/api"
import type { LicenceStatus } from "~/types/enums"
import type {
  AccountPermissionType,
  PermissionType,
  PermissionVerb,
} from "~/types/permissions"
import type { DropdownOption } from "~/types/props"

import isEqual from "lodash/isEqual"
import { DateTime } from "luxon"

import { LicenceStatuses } from "~/types/defs"
import {
  permissionConstants as constants,
  permissionConstants,
  permissionVerbMutating,
  permissionTypes as types,
  permissionVerbs as verbs,
} from "~/types/permissions"
import { unionArrays } from "~/utils/arrays"

export function getAllUserPermissions(user: User) {
  const userPermissions = user.roles.flatMap((role) => role.permissions)
  const groupPermissions = user.groups
    .flatMap((group) => group.roles)
    .flatMap((role) => role.permissions)

  const permissions = unionArrays(userPermissions, groupPermissions)

  if (user.accountId) {
    permissions.push(
      `${types.Account}${constants.Separator}${user.accountId}${constants.Separator}${verbs.Read}`,
    )
  }

  return permissions
}

export function checkGlobalPermissions(
  permissions: string[],
  typeToCheck: PermissionType,
  resourceIdToCheck: string,
  verbToCheck: PermissionVerb,
): boolean {
  return permissions
    .filter(
      (permission) =>
        permission === constants.Wildcard ||
        permission.split(constants.Separator).length === 3,
    )
    .some((permission) =>
      checkGlobalPermission(
        permission,
        typeToCheck,
        resourceIdToCheck,
        verbToCheck,
      ),
    )
}

export function checkPartialGlobalPermissions(
  permissions: string[],
  validTypes: PermissionType[],
  validVerbs: PermissionVerb[],
): boolean {
  return permissions
    .filter(
      (permission) =>
        permission === constants.Wildcard ||
        permission.split(constants.Separator).length === 3,
    )
    .some((permission) =>
      checkPartialGlobalPermission(permission, validTypes, validVerbs),
    )
}

export function checkPartialGlobalPermission(
  permission: string,
  validTypes: PermissionType[],
  validVerbs: PermissionVerb[],
): boolean {
  if (permission === constants.Wildcard) return true

  const permissionParts = getPermissionParts(permission, 3)
  const type = permissionParts[0] as PermissionType

  if (type !== constants.Wildcard && !validTypes.includes(type)) {
    return false
  }

  const verb = permissionParts[2] as PermissionVerb
  return verb === constants.Wildcard || validVerbs.includes(verb)
}

export function checkPartialAccountPermissions(
  permissions: string[],
  validTypes: AccountPermissionType[],
  validVerbs: PermissionVerb[],
): boolean {
  return permissions
    .filter(
      (permission) =>
        permission === constants.Wildcard ||
        permission.split(constants.Separator).length === 5,
    )
    .some((permission) =>
      checkPartialAccountPermission(permission, validTypes, validVerbs),
    )
}

export function checkPartialAccountPermission(
  permission: string,
  validTypes: AccountPermissionType[],
  validVerbs: PermissionVerb[],
): boolean {
  if (permission === constants.Wildcard) return true

  const permissionParts = getPermissionParts(permission, 5)
  const type = permissionParts[0] as PermissionType

  if (type !== constants.Wildcard && type !== "Account") {
    return false
  }

  const accountType = permissionParts[2] as AccountPermissionType

  if (accountType !== constants.Wildcard && !validTypes.includes(accountType)) {
    return false
  }

  const verb = permissionParts[4] as PermissionVerb
  return verb === constants.Wildcard || validVerbs.includes(verb)
}

export function checkGlobalPermission(
  permission: string,
  typeToCheck: PermissionType,
  resourceIdToCheck: string,
  verbToCheck: PermissionVerb,
): boolean {
  if (permission === constants.Wildcard) return true

  const permissionParts = getGlobalPermissionParts(permission)
  const type = permissionParts[0] as PermissionType

  if (type !== constants.Wildcard && type !== typeToCheck) {
    return false
  }

  const idPassesCheck = checkResourceId(permissionParts[1], resourceIdToCheck)

  if (!idPassesCheck) {
    return false
  }

  const verb = permissionParts[2] as PermissionVerb
  return verb === constants.Wildcard || verb === verbToCheck
}

export function checkAccountPermissions(
  permissions: string[],
  accountIdToCheck: string | undefined,
  resourceTypeToCheck: AccountPermissionType,
  resourceIdToCheck: string | (string | undefined)[],
  verbToCheck: PermissionVerb,
): boolean {
  return permissions
    .filter(
      (permission) =>
        permission === constants.Wildcard ||
        permission.split(constants.Separator).length === 5,
    )
    .some((permission) =>
      checkAccountPermission(
        permission,
        accountIdToCheck,
        resourceTypeToCheck,
        resourceIdToCheck,
        verbToCheck,
      ),
    )
}

export function checkAccountPermission(
  permission: string,
  accountIdToCheck: string | undefined,
  resourceTypeToCheck: AccountPermissionType,
  resourceIdToCheck: string | (string | undefined)[],
  verbToCheck: PermissionVerb,
): boolean {
  if (permission === constants.Wildcard) return true

  const permissionParts = getAccountPermissionParts(permission)

  const type = permissionParts[0] as PermissionType

  if (type !== constants.Wildcard && type !== types.Account) {
    return false
  }

  const accountId = permissionParts[1]

  if (
    accountId !== constants.Wildcard &&
    accountId !== (accountIdToCheck ?? "null")
  ) {
    return false
  }

  const subType = permissionParts[2]

  if (subType !== constants.Wildcard && subType !== resourceTypeToCheck) {
    return false
  }

  const idPassesCheck = checkResourceId(permissionParts[3], resourceIdToCheck)

  if (!idPassesCheck) {
    return false
  }

  const verb = permissionParts[4] as PermissionVerb
  return verb === constants.Wildcard || verb === verbToCheck
}

export function getAccountPermissionParts(
  permission: string,
): ReadonlyTuple<string, 5> {
  // This is a safe cast because we know the length is 5
  return getPermissionParts(permission, 5) as unknown as ReadonlyTuple<
    string,
    5
  >
}

export function getGlobalPermissionParts(
  permission: string,
): ReadonlyTuple<string, 3> {
  // This is a safe cast because we know the length is 3
  return getPermissionParts(permission, 3) as unknown as ReadonlyTuple<
    string,
    3
  >
}

export function getPermissionParts(
  permission: string,
  requiredLength: number,
): string[] {
  const permissionParts = permission.split(constants.Separator)

  if (permissionParts.length !== requiredLength) {
    throw new Error(`Permission is not in valid ${requiredLength} part format`)
  }

  return permissionParts
}

export function checkResourceId(
  requiredId: string,
  idToCheck: string | (string | undefined)[],
): boolean {
  if (requiredId === constants.Wildcard) return true

  const resourceId = requiredId.split(constants.IdSeparator)
  const resourceIdToCheckParts = Array.isArray(idToCheck)
    ? idToCheck
    : idToCheck.split(constants.IdSeparator)

  if (resourceId.length !== resourceIdToCheckParts.length) return false

  // If there is any part of the ID that isn't a wildcard and doesn't match the required ID, then we fail the check
  return !resourceId.some(
    (part, i) =>
      part !== constants.Wildcard &&
      part !== (resourceIdToCheckParts[i] ?? "null"),
  )
}

export function canPermissionModify(permission: string): boolean {
  const permissionHasMutatingVerb = Object.keys(verbs)
    .filter((verb) => isMutatingVerb(verb as PermissionVerb))
    .some((allowedVerb) => permission.endsWith(allowedVerb))

  return (
    permission === constants.Wildcard ||
    permission.endsWith(constants.Wildcard) ||
    permissionHasMutatingVerb
  )
}

export function isMutatingVerb(verb: PermissionVerb) {
  return permissionVerbMutating[verb] ?? false
}

export function getCurrentLicence(licences: Licence[]) {
  return licences.find((licence) => isLicenceValidDate(licence))
}

export function getLicenceByStatus(licences: Licence[], status: LicenceStatus) {
  const licence = getCurrentLicence(licences)

  if (licence?.status !== status) return undefined

  return licence
}

export function isLicenceValidDate(licence: Licence) {
  const startDate = DateTime.fromISO(licence.startDate)
  const endDate = DateTime.fromISO(licence.endDate)

  return (
    startDate.diffNow().milliseconds <= 0 && endDate.diffNow().milliseconds > 0
  )
}

export function isLicenceActive(licence: Licence) {
  return (
    (licence.status === LicenceStatuses.active ||
      licence.status === LicenceStatuses.gracePeriod) &&
    isLicenceValidDate(licence)
  )
}

const partTransform = (data: any) => ({
  label: `${data.manufacturerName} ${data.model}`,
  value: data.id,
})

const defaultTransform = (data: any) => ({
  label: data.name,
  value: data.id,
})

interface ItemEndpoint {
  global: string | false
  account: ((id: string) => string) | false
  allAccounts: string | false
  transform: ((data: any) => DropdownOption) | false
}

export const permissionPickerEndpoints: Record<
  PermissionType | AccountPermissionType,
  ItemEndpoint
> = {
  // Global
  Engine: {
    global: "/data/engines/meta",
    account: false,
    allAccounts: false,
    transform: partTransform,
  },
  GasEngine: {
    global: "/data/gas-engines/meta",
    account: false,
    allAccounts: false,
    transform: partTransform,
  },
  SolarPanel: {
    global: "/data/solar-panels/meta",
    account: false,
    allAccounts: false,
    transform: partTransform,
  },
  WindTurbine: {
    global: "/data/turbines/meta",
    account: false,
    allAccounts: false,
    transform: partTransform,
  },
  Battery: {
    global: "/data/batteries/meta",
    account: false,
    allAccounts: false,
    transform: partTransform,
  },
  HeatSource: {
    global: "/data/heat-sources/meta",
    account: false,
    allAccounts: false,
    transform: partTransform,
  },
  Manufacturer: {
    global: "/data/manufacturers",
    account: false,
    allAccounts: false,
    transform: defaultTransform,
  },
  Account: {
    global: "/accounts",
    account: false,
    allAccounts: false,
    transform: defaultTransform,
  },
  FuelCurve: {
    global: false,
    account: false,
    allAccounts: false,
    transform: false,
  },
  Licence: {
    global: false,
    account: false,
    allAccounts: false,
    transform: false,
  },
  LicencePlan: {
    global: false,
    account: false,
    allAccounts: false,
    transform: false,
  },
  GlobalAnnouncement: {
    global: "/global-announcements",
    account: false,
    allAccounts: false,
    transform: defaultTransform,
  },

  // Account
  User: {
    global: "/users",
    account: (accountId) => `/users?accountId=${accountId}`,
    allAccounts: "/users",
    transform: (user: User) => ({
      label: user.fullName
        ? `${user.fullName} (${user.username})`
        : user.username,
      value: user.id,
    }),
  },
  UserGroup: {
    global: "/user-groups",
    account: (accountId) => `/user-groups?accountId=${accountId}`,
    allAccounts: "/user-groups",
    transform: defaultTransform,
  },
  ExternalUser: {
    global: false,
    account: (accountId) => `/external-users?accountId=${accountId}`,
    allAccounts: false,
    transform: (externalUser: ExternalUser) => ({
      label: externalUser.userFullName
        ? `${externalUser.userFullName} (${externalUser.userUsername})`
        : (externalUser.userUsername ?? externalUser.id),
      value: externalUser.id,
    }),
  },
  Project: {
    account: (accountId) => `/projects?accountId=${accountId}`,
    allAccounts: "/projects",
    global: false,
    transform: (project: Project) => ({
      label: project.name,
      value: `${project.projectGroupId ?? "null"}${
        permissionConstants.IdSeparator
      }${project.id}`,
    }),
  },
  ProjectGroup: {
    account: (accountId) => `/project-groups?accountId=${accountId}`,
    allAccounts: "/project-groups",
    global: false,
    transform: defaultTransform,
  },
  PermissionRole: {
    account: false,
    global: false,
    transform: false,
    allAccounts: false,
  },
}

export function isPermissionRoleEqual(
  a: PermissionRoleToCreate,
  b: PermissionRoleToCreate,
): boolean {
  return (
    a.accountId === b.accountId &&
    isEqual(a.customPermissions, b.customPermissions) &&
    a.presetScope === b.presetScope &&
    a.presetSubjectId === b.presetSubjectId &&
    a.presetSubjectType === b.presetSubjectType &&
    a.presetType === b.presetType &&
    a.wildcardAccount === b.wildcardAccount
  )
}
