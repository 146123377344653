import type { Account } from "~/types/api"

import { defineStore } from "pinia"

import { accountsQueryKey } from "~/api/queryKeys"
import { getAccountSelf } from "~/api/requests/accounts"

export const useAccountStore = defineStore("account", () => {
  const userAccount = ref<Account | undefined>(undefined)
  const projectAccount = ref<Account | undefined>(undefined)

  const authStore = useAuthStore()
  const queryClient = getQueryClient()

  const accountId = authStore.user?.accountId

  const currentAccount = computed(
    () => projectAccount.value ?? userAccount.value,
  )

  async function fetchUserAccount() {
    if (accountId == null) {
      userAccount.value = undefined
      return
    }
    userAccount.value = await queryClient.fetchQuery<Account>({
      queryKey: [accountsQueryKey, accountId],
      queryFn: async () => {
        const account = await getAccountSelf()
        if (account.id !== accountId) {
          throw new Error("Account ID mismatch")
        }
        return account
      },
    })
  }

  function setProjectAccount(account: Account | undefined) {
    projectAccount.value = account
  }

  return {
    userAccount,
    projectAccount,
    currentAccount,
    fetchUserAccount,
    setProjectAccount,
  }
})
