import type { AccountPermissionType, PermissionVerb } from "~/types/permissions"

import { useAuthStore } from "~/stores/auth"
import {
  checkAccountPermissions,
  checkPartialAccountPermissions,
  getAllUserPermissions,
} from "~/utils/permissions"

export function hasAccountPermission(
  accountId: string | undefined,
  resourceTypeToCheck: AccountPermissionType,
  resourceIdToCheck: string | (string | undefined)[],
  verbToCheck: PermissionVerb,
) {
  const authStore = useAuthStore()

  if (!authStore.user) return false

  return checkAccountPermissions(
    getAllUserPermissions(authStore.user),
    accountId,
    resourceTypeToCheck,
    resourceIdToCheck,
    verbToCheck,
  )
}

export function hasPartialAccountPermission(
  validTypes: AccountPermissionType[],
  validVerbs: PermissionVerb[],
) {
  const authStore = useAuthStore()

  if (!authStore.user) return false

  return checkPartialAccountPermissions(
    getAllUserPermissions(authStore.user),
    validTypes,
    validVerbs,
  )
}
