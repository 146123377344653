import type { ApiErrorResponse } from "~/types/apiResponses"

import { FetchError } from "ofetch"

import { useAuthStore } from "~/stores/auth"
import { modalAlert, modalIcons } from "~/stores/modals"

export type AnyError =
  | string
  | ApiErrorResponse
  | Error
  | FetchError<ApiErrorResponse>
  | null
  | undefined

export function getValidationMessage(
  error: ApiErrorResponse | undefined,
  path: string,
) {
  return error?.status === "validationError"
    ? error?.validationMessages[path]?.join(", ")
    : undefined
}

export function getErrorMessage(err: AnyError) {
  if (err == null) return undefined

  if (err instanceof FetchError && err.data != null) {
    return getErrorMessage(err.data as ApiErrorResponse)
  }

  if (typeof err === "string") {
    return err
  }

  if (err instanceof Error) {
    if (err instanceof FetchError) {
      return err.response ? err.response.statusText : "No response from server."
    }
    return err.message
  }

  if (err.status === "validationError") {
    return "A validation error has occurred, please correct the errors in the form."
  }

  if (err.status === "error" && err.code) {
    const {
      $i18n: { t: $t },
    } = useNuxtApp()
    return $t(`apiErrors.${err.code}`, err.parameters)
  }

  return err.message
}

export function processApiError(err: any): any {
  const authStore = useAuthStore()

  if (err.name === "FetchError") {
    const fetchError = err as FetchError

    if (fetchError.statusCode === 401) {
      authStore.resetAuth()
    } else if (
      fetchError.statusCode === 403 &&
      fetchError.data?.message === "mfaRequired"
    ) {
      authStore.mfaVerified = false
      navigateTo("/mfa")
      return
    } else if (fetchError.statusCode === 403) {
      showError({ statusCode: 403, stack: undefined })
      throw err
    }
  }

  throw err
}

export function handleApiError(
  err: any,
  objectName?: string,
  objectType?: string,
) {
  if (err?.data?.message === "mfaRequired") {
    navigateTo("/mfa")
    return
  }

  let title = getErrorMessage(err as AnyError) ?? "An error occurred."
  let message: string | undefined

  if (title?.includes("Reference constraint violation")) {
    title = `The ${objectType ?? "item"}${
      objectName ? ` '${objectName}'` : ""
    } is currently in use. `
    message = "Remove the usages and then try again."
  }

  modalAlert(title, modalIcons.warning, message)
}
