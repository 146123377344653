import mitt from "mitt"

export default defineNuxtPlugin(() => {
  const emitter = mitt<Record<string, any>>()

  return {
    provide: {
      events: {
        on: emitter.on,
        off: emitter.off,
        emit: emitter.emit,
      },
    },
  }
})
