const _black = "#000"
const _white = "#fff"
const _transparent = "transparent"
const _current = "currentColor"
const _status = {"error":"#BD5041","warning":"#B89437","success":"#30B666"}
const _forest = {"100":"#95f6bc","200":"#6ee39d","300":"#43cd7a","400":"#30b666","500":"#309759","600":"#257846","700":"#10673d","800":"#0b4d2d","900":"#04321c"}
const _ebony = {"100":"#afc0ff","200":"#7f96ed","300":"#5d74ce","400":"#4558a4","500":"#293a79","600":"#1c274f","700":"#131b39","800":"#0c152c","900":"#091021"}
const _red = {"600":"#bd5041","700":"#9c3e31","800":"#812e27"}
const _dusk = {"100":"#e4ecf1","200":"#c1ccda","300":"#9ca3ba","400":"#8990aa","500":"#606781","600":"#4d546c"}
const _auto = "auto"
const config = { "black": _black, "white": _white, "transparent": _transparent, "current": _current, "status": _status, "forest": _forest, "ebony": _ebony, "red": _red, "dusk": _dusk, "auto": _auto,  }
export { config as default, _black, _white, _transparent, _current, _status, _forest, _ebony, _red, _dusk, _auto }