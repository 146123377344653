import { $fetch } from "ofetch"

export default defineNuxtPlugin(() => {
  const ofetch = $fetch.create({
    baseURL: getBaseURL(),
  })

  return {
    provide: {
      ofetch,
    },
  }
})
