import type { Component } from "vue"

export interface NavTab {
  to: string
  label: string
  count?: number
  disabled?: boolean
  hidden?: boolean
  exact?: boolean
}

export interface Option<AllowUndefined extends boolean = false> {
  label: string
  value: AllowUndefined extends true ? string | undefined : string
}

export interface InputBoxProps<T extends string | number = string> {
  modelValue: T | undefined
  validationError?: string
  autocomplete?: string
  disabled?: boolean
  min?: number
  max?: number
  maxlength?: number
  minlength?: number
  pattern?: string
  name?: string
  placeholder?: string
  optional?: boolean
  type?: string
  label?: string
  prefixUnit?: string
  postfixUnit?: string
  step?: string | number
  eagerUpdate?: boolean
  helpText?: string
  warningText?: string
  class?: string
  containerClass?: string
  inputClass?: string
  prefixClass?: string
  postfixClass?: string
}

export type NumericInputBoxProps = Omit<InputBoxProps<number>, "type"> & {
  displayMultiplier?: number
}

export type NumberColumnFormat =
  | "filesize"
  | "price"
  | "energy"
  | "costPerEnergy"

export type TableColumnFormat = "datetime" | "number"

export type TableColumnAlignment = "left" | "center" | "right"

export type ButtonVariant = "primary" | "secondary" | "tertiary" | "danger"

export enum ButtonVariants {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  TERTIARY = "tertiary",
  DANGER = "danger",
}

interface BaseColumn<T> {
  title: string
  width?: string
  headingAlignment?: TableColumnAlignment
  contentAlignment?: TableColumnAlignment
  class?: string | ((item: T, value: any) => string | undefined)
  totalClass?: string | ((value: any) => string | undefined)
}

export interface LinkCardColumn<T> extends BaseColumn<T> {
  type: "linkCard"
  titleKey: keyof T | ((item: T) => string | undefined)
  descriptionKey?: keyof T
  link: (item: T) => string | undefined
  arrow?: boolean
}

export interface StringColumn<T> extends BaseColumn<T> {
  type?: "string"
  key: string | ((item: T) => string | undefined) | undefined
  format?: TableColumnFormat
  dateTimeOptions?: Intl.DateTimeFormatOptions
}

export interface NumberColumn<T> extends BaseColumn<T> {
  type: "number"
  format?: NumberColumnFormat
  formatter?: (item: T, value: number) => string
  totalFormatter?: (value: number) => string
  key: string | ((item: T) => number | undefined) | undefined
  unit?: string
  decimals?: number
}

export interface CustomColumn<T> extends BaseColumn<T> {
  type: "custom"
  component: ReturnType<typeof resolveComponent> | Component
  props: Record<string, (item: T) => any>
}

export interface PillColumn<T> extends Omit<StringColumn<T>, "type"> {
  type: "pill"
}

export type TableColumn<T> =
  | LinkCardColumn<T>
  | StringColumn<T>
  | NumberColumn<T>
  | PillColumn<T>
  | CustomColumn<T>

export interface TableAction<T> {
  action: (item: T) => void
  visible?: MaybeRef<boolean> | ((item: T) => MaybeRef<boolean>)
  disabled?: MaybeRef<boolean> | ((item: T) => MaybeRef<boolean>)
  loading?: MaybeRef<boolean> | ((item: T) => MaybeRef<boolean>)
  icon: string
  variant: ButtonVariant
  tooltip?: MaybeRef<string> | ((item: T) => MaybeRef<string>)
}

export type DropdownOption = Option<boolean> & {
  textClass?: string
}

export interface DropdownProps<T extends string | string[] = string> {
  options: DropdownOption[]
  validationError?: string
  label?: string
  modelValue?: T
  optional?: boolean
  disabled?: boolean
  multiple?: boolean
  helpText?: string
  class?: string
  wrapperClass?: string
  allowCustom?: boolean
}

export type MultipleDropdownProps = Omit<DropdownProps<string[]>, "multiple">

export type VisualisationSupportedCharts = "line" | "bar" | "doughnut"

export interface SearchableListProps {
  options: DropdownOption[]
  modelValue?: DropdownOption | DropdownOption[]
  allowCustom?: boolean
}

export interface ScenarioChecklistEntry {
  id: number
  optional?: boolean
  text: string
  status: "completed" | "in-progress" | "todo"
  onClick?: () => void
  group?: string
}

export interface ScenarioChecklistProps {
  checklist: ScenarioChecklistEntry[]
}

export interface GanttEntryLine {
  start: string | Date
  end: string | Date
  color: string
}

export interface GanttEntry {
  name: string
  url?: string
  entries: GanttEntryLine[]
}

export interface GanttChartProps {
  entries: GanttEntry[]
  noMonths?: boolean
  startDate?: Date | string
  endDate?: Date | string
}

export interface Time {
  hours: number
  minutes: number
  seconds: number
}

export type ReportType = "finance" | "carbon" | "levelised-cost" | "downtime"
