import type { NavTab } from "~/types/props"

export function checkValidTabPath(tabs: NavTab[], path: string) {
  const pathInTabs = tabs
    .filter((tab) => !tab.disabled && !tab.hidden)
    .map((tab) => tab.to)
    .some((tab) => {
      if (tab === "/") {
        return path === tab
      } else {
        return stripEndingSlash(path).startsWith(stripEndingSlash(tab))
      }
    })

  if (!pathInTabs && tabs.length > 0) {
    navigateTo(tabs[0]!.to)
  }
}

export function isActive(to: string, currentPath: string) {
  if (to === "/") {
    return currentPath === to
  } else {
    return stripEndingSlash(currentPath).startsWith(stripEndingSlash(to))
  }
}

export function stripEndingSlash(input: string) {
  if (input.endsWith("/") && input !== "/") {
    return input.substring(0, input.length - 1)
  }

  return input
}

export function normalizePath(path = ""): string {
  // Remove query string
  const config = useRuntimeConfig()

  // Assertion is safe because a split string always has at least one element
  let result = path.split("?")[0]!

  // Remove base path
  if (config.app.baseURL) {
    result = result.replace(config.app.baseURL, "/")
  }

  // Remove redundant / from the end of path
  result = stripEndingSlash(result)

  // Remove duplicate slashes
  result = result.replace(/\/+/g, "/")

  return result
}
