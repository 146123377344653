import type {
  ExternalUser,
  ExternalUserInviteMeta,
  InviteMeta,
  User,
  UserGroup,
} from "~/types/api"

export function getUsers() {
  return httpRequest<User[]>("/users")
}

export function getUser(userId: string) {
  return httpRequest<User>(`/users/${userId}`)
}

export function getUserSelf() {
  return httpRequest<User>("/users/@me")
}

export function getUserGroups() {
  return httpRequest<UserGroup[]>("/user-groups")
}

export function getUserGroup(groupId: string) {
  return httpRequest<UserGroup>(`/user-groups/${groupId}`)
}

export function getInvites() {
  return httpRequest<InviteMeta[]>("/invites")
}

export function getInviteMeta(token: string) {
  return httpRequest<InviteMeta>(`/invites/meta/${token}`)
}

export function getExternalInviteMeta(token: string) {
  return httpRequest<ExternalUserInviteMeta>(
    `/external-users/invite/meta/${token}`,
  )
}

export function getExternalUsersForAccount(accountId: string) {
  return httpRequest<ExternalUser[]>(`/external-users`, "get", {
    query: { accountId },
  })
}
