import type { ModuleOptions as PersistedStateModuleOptions } from "@pinia-plugin-persistedstate/nuxt"
import type { SessionTokenResponse, User } from "~/types/api"
import type { SuccessResponse } from "~/types/apiResponses"

import { defineStore } from "pinia"

import { usersQueryKey } from "~/api/queryKeys"
import { httpRequest } from "~/composables/fetch"
import { useQuerySubscribe } from "~/composables/tanstack"

export const useAuthStore = defineStore(
  "auth",
  () => {
    const state = reactive({
      user: undefined as User | undefined,
      token: undefined as string | undefined,
      ephemeral: false,
      mfaVerified: false,
    })

    useQuerySubscribe<User>([usersQueryKey, "@me"], (user) => {
      state.user = user
    })

    async function fetchUser() {
      if (!state.token) return
      const user = await getQueryClient().fetchQuery({
        queryKey: [usersQueryKey, "@me"],
        queryFn: async () =>
          httpRequest<User>("/users/@me", "get", {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }),
      })

      state.user = user
      return user
    }

    const isLoggedIn = computed(() => state.token != null && state.user != null)

    const requiresMfaRegister = computed(
      () => state.user != null && state.user.mfaTokens.length === 0,
    )

    async function login(
      identifier: string | undefined,
      password: string | undefined,
      ephemeral: boolean,
    ) {
      const { $ofetch } = useNuxtApp()

      const loginResponse = await $ofetch<
        SuccessResponse<SessionTokenResponse>
      >("/auth/login", {
        body: {
          identifier,
          password,
          ephemeral,
        },
        method: "POST",
      })

      state.token = loginResponse.data.token
      state.ephemeral = ephemeral

      await fetchUser()
    }

    async function logout() {
      if (!state.token) return

      const { $ofetch } = useNuxtApp()

      try {
        await $ofetch("/auth/logout", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
      } catch {}

      resetAuth()

      navigateTo("/login")
    }

    function resetAuth() {
      state.token = undefined
      state.mfaVerified = false
      useLastPagesStore().$reset()
      getQueryClient().removeQueries({
        queryKey: [usersQueryKey, "@me"],
      })
    }

    return {
      ...toRefs(state),
      isLoggedIn,
      requiresMfaRegister,
      login,
      logout,
      fetchUser,
      resetAuth,
    }
  },
  {
    persist: {
      key: "session",
      paths: ["token", "mfaVerified", "ephemeral"],
      storage: {
        getItem(key) {
          return persistedState.cookies.getItem(key)
        },
        setItem(key, valueJson) {
          const value = JSON.parse(valueJson) as {
            token: string | undefined
            mfaVerified: boolean
            ephemeral: boolean
          }

          const cookieOptions = (
            useRuntimeConfig().public
              .persistedState as PersistedStateModuleOptions
          ).cookieOptions

          if (value.ephemeral) {
            // If the token is ephemeral, we only want to persist it for the session
            cookieOptions.maxAge = undefined
            cookieOptions.expires = undefined
          }

          persistedState
            .cookiesWithOptions(cookieOptions)
            .setItem(key, valueJson)
        },
      },
    },
  },
)
