import type { PermissionType, PermissionVerb } from "~/types/permissions"

import { useAuthStore } from "~/stores/auth"
import {
  checkGlobalPermissions,
  getAllUserPermissions,
} from "~/utils/permissions"

export function hasGlobalPermission(
  typeToCheck: PermissionType,
  resourceIdToCheck: string,
  verbToCheck: PermissionVerb,
) {
  const authStore = useAuthStore()

  if (!authStore.user) return false

  return checkGlobalPermissions(
    getAllUserPermissions(authStore.user),
    typeToCheck,
    resourceIdToCheck,
    verbToCheck,
  )
}

export function hasPartialGlobalPermission(
  validTypes: PermissionType[],
  validVerbs: PermissionVerb[],
) {
  const authStore = useAuthStore()

  if (!authStore.user) return false

  return checkPartialGlobalPermissions(
    getAllUserPermissions(authStore.user),
    validTypes,
    validVerbs,
  )
}
