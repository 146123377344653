import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js"
import ChartDataLabels from "chartjs-plugin-datalabels"
import zoomPlugin from "chartjs-plugin-zoom"
import { defu } from "defu"

import tailwindConfig from "#tailwind-config"

export default defineNuxtPlugin((_) => {
  ChartJS.register(
    ArcElement,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    ChartDataLabels,
    PointElement,
    LineElement,
    Filler,
    zoomPlugin,
  )

  ChartJS.defaults.animation = false
  ChartJS.defaults.responsive = true
  ChartJS.defaults.interaction = {
    intersect: false,
    mode: "index",
    axis: "x",
    includeInvisible: false,
  }
  ChartJS.defaults.maintainAspectRatio = false
  ChartJS.defaults.indexAxis = "x"
  ChartJS.defaults.layout = defu(
    {
      padding: {
        right: 30,
      },
    },
    ChartJS.defaults.layout,
  )
  ChartJS.defaults.plugins.zoom.limits = {
    x: {
      minRange: 2,
    },
  }

  const defaultScaleOptions = {
    title: {
      color: tailwindConfig.theme.colors.ebony[600],
      font: {
        size: 13,
        family: "Fira Sans",
        weight: 500,
      },
    },
    grid: {
      display: false,
    },
    ticks: {
      color: tailwindConfig.theme.colors.dusk[300],
      font: {
        size: 12,
        family: "Fira Sans",
        weight: 500,
      },
    },
  } as const

  ChartJS.defaults.scales = defu(
    {
      linear: {
        type: "linear",
        ...defaultScaleOptions,
        grid: {
          display: true,
        },
      },
      category: {
        type: "category",
        ...defaultScaleOptions,
      },
      time: {
        type: "time",
        ...defaultScaleOptions,
      },
      timeseries: {
        type: "timeseries",
        ...defaultScaleOptions,
      },
    } as const,
    ChartJS.defaults.scales,
  )

  ChartJS.defaults.plugins.legend = defu(
    {
      display: true,
      position: "bottom",
      align: "end",
      labels: {
        font: {
          family: "Fira Sans",
          weight: 700,
          lineHeight: 15,
          size: 13,
        },
        color: tailwindConfig.theme.colors.ebony[600],
        useBorderRadius: true,
        borderRadius: 5,
        boxHeight: 15,
        boxWidth: 15,
      },
    } as const,
    ChartJS.defaults.plugins.legend,
  )

  ChartJS.defaults.plugins.datalabels = defu(
    {
      display: false,
    } as const,
    ChartJS.defaults.plugins.datalabels,
  )

  ChartJS.defaults.plugins.tooltip = defu(
    {
      backgroundColor: tailwindConfig.theme.backgroundColor.ebony[900],
    } as const,
    ChartJS.defaults.plugins.tooltip,
  )
})
