import type { LicenceLimitKeys } from "./api"
import type {
  AnnouncementType,
  CapitalType,
  ChartSeriesType,
  ChpType,
  CriterionAction,
  CriterionOperator,
  DayOfWeek,
  DeferralPlan,
  EffectValueType,
  EnergyUnit,
  ExternalUserAccessLevel,
  FinancialAnalysisLineId,
  FuelType,
  GasType,
  GridFrequency,
  HeatSourceType,
  HeatSourceUse,
  HotwellLiquid,
  InstallationType,
  LicenceFlag,
  LicenceStatus,
  MassUnit,
  ModuleSource,
  OutputScenarioModule,
  PartVisibility,
  ProfitLossEntryUnit,
  ProfitLossScenarioModule,
  ProfitLossType,
  ProfitLossValueType,
  ProjectCurrency,
  ReportGranularity,
  ScenarioModule,
  SourceType,
  UserSeatType,
  ValueMapSource,
  ValueMapType,
} from "~/types/enums"
import type { PanelTechnology } from "~/types/parts"
import type {
  AccountPermissionType,
  PermissionRolePresetScope,
  PermissionRolePresetType,
  PermissionRolePresetTypeNoCustom,
  PermissionRoleSubjectType,
  PermissionType,
} from "~/types/permissions"

import { tv } from "tailwind-variants"

export const profitLossCategories = [
  "Energy Generation",
  "Electricity Usage",
  "Operation",
  "Maintenance",
  "Fuel",
  "Statistics",
]

export const profitLossCategoryLabels = profitLossCategories.map((category) => {
  return {
    label: category,
    value: category,
  }
})

profitLossCategoryLabels.push({
  label: "Custom",
  value: "custom",
})

export const fuelTypeLabels: Record<FuelType, Uppercase<FuelType>> = {
  en590: "EN590",
  hvo: "HVO",
  uco: "UCO",
  ucome: "UCOME",
}

export const gasTypeLabels: Record<GasType, Uppercase<GasType>> = {
  bg0: "BG0",
  bg20: "BG20",
  bg40: "BG40",
  bg50: "BG50",
}

export const panelTechnologies: Record<
  PanelTechnology,
  Uppercase<PanelTechnology>
> = {
  cSi: "CSI",
  cIG: "CIG",
  cdTe: "CDTE",
}

export const chpTypeLabels: Record<ChpType, string> = {
  noChp: "No CHP",
  customCoefficient: "Custom Coefficient",
  jacketWater: "Jacket Water",
  exhaust: "Exhaust",
  jacketAndExhaust: "Jacket and Exhaust",
}

export const trackingTypeLabels: Record<string, string> = {
  fixed: "Fixed",
  singleHNS: "Single Horizontal Axis (North-South)",
  twoAxis: "Two-axis Tracking",
  vertical: "Vertical Axis Tracking",
  singleHEW: "Single Horizontal Axis (East-West)",
  singleI: "Single Inclined Axis (North-South)",
}

export const heatSourceTypeLabels: Record<HeatSourceType, string> = {
  boiler: "Boiler",
  heatPump: "Heat Pump",
  resistive: "Resistive",
}

export const heatSourceUseLabels: Record<HeatSourceUse, string> = {
  hotWater: "Hot Water",
  heatingLow: "Heating Low (35C)",
  heatingHigh: "Heating High (55C)",
}

export const hotwellLiquidLabels: Record<HotwellLiquid, string> = {
  water: "Water",
  waterEth50: "Water and Ethylene Glycol 50:50",
  waterProp50: "Water and Propylene Glycol 50:50",
  dynaleneHC30: "Dynalene HC-30",
  galdenHT200: "Galden FT-200",
  fluorinertFC72: "Fluorinert FC-72",
}

export const profitLossUnitLabels: {
  [key in ProfitLossEntryUnit]: string
} = {
  perKwhGenerated: "kWh",
  perKwhHeatGenerated: "kWh",
  perKwhGeneratedTotal: "kWh",
  perKwhExcess: "kWh",
  perKwhResidual: "kWh",
  perKwhHeatExcess: "kWh",
  perKwhHeatResidual: "kWh",
  perKwPeakPa: "kWp per Annum",
  perFuelLitre: "L",
  perFuelKg: "kg",
  perFuelKwh: "kWh",
  perHour: "hour",
  perDay: "day",
  perMonth: "month",
  perYear: "year",
  perHourRun: "hour",
  perKwhGas: "kWh",
  perKwhElectricity: "kWh",
  perKwhOnSite: "kWh",
  perKwhLoss: "kWh",
  perHourDsr: "hour",
  maintenance: "event",
}

const commonUnits: ProfitLossEntryUnit[] = ["perDay", "perMonth", "perYear"]

export const acceptableProfitLossUnitsPerModule: Record<
  ProfitLossScenarioModule,
  ProfitLossEntryUnit[]
> = {
  battery: [
    ...commonUnits,
    "perKwhGenerated",
    "perKwhGeneratedTotal",
    "perKwhExcess",
    "perKwhResidual",
    "perKwPeakPa",
  ],
  chp: [
    ...commonUnits,
    "perKwhHeatGenerated",
    "perKwhHeatExcess",
    "perKwhHeatResidual",
  ],
  engineGeneration: [
    ...commonUnits,
    "perKwhGenerated",
    "perKwhGeneratedTotal",
    "perKwhExcess",
    "perKwhResidual",
    "perFuelKg",
    "perFuelLitre",
    "perHourRun",
  ],
  gasEngine: [
    ...commonUnits,
    "perKwhGenerated",
    "perKwhGeneratedTotal",
    "perKwhExcess",
    "perKwhResidual",
    "perFuelKg",
    "perFuelKwh",
    "perHourRun",
  ],
  headless: [
    ...commonUnits,
    "perKwhExcess",
    "perKwhResidual",
    "perKwhHeatExcess",
    "perKwhHeatResidual",
  ],
  heatSource: [
    ...commonUnits,
    "perKwhGenerated",
    "perKwhGeneratedTotal",
    "perKwhExcess",
    "perKwhResidual",
    "perKwhLoss",
    "perKwhGas",
    "perKwhElectricity",
    "perKwhOnSite",
  ],
  hotwell: [
    ...commonUnits,
    "perKwhGenerated",
    "perKwhGeneratedTotal",
    "perKwhExcess",
    "perKwhResidual",
    "perKwhLoss",
  ],
  solar: [
    ...commonUnits,
    "perKwhGenerated",
    "perKwhGeneratedTotal",
    "perKwhExcess",
    "perKwhResidual",
    "perKwPeakPa",
    "perHourDsr",
  ],
  wind: [
    ...commonUnits,
    "perKwhGenerated",
    "perKwhGeneratedTotal",
    "perKwhExcess",
    "perKwhResidual",
    "perHourDsr",
  ],
}

export const energyUnitLabels: {
  [key in EnergyUnit]: string
} = {
  kwh: "kWh",
  mwh: "MWh",
  gwh: "GWh",
}

export const energyUnitDivisors: {
  [key in EnergyUnit]: number
} = {
  kwh: 1,
  mwh: 1_000,
  gwh: 1_000_000,
}

export const massUnitLabels: {
  [key in MassUnit]: string
} = {
  kg: "kg",
  t: "t",
  kt: "kt",
}

export const massUnitDivisors: {
  [key in MassUnit]: number
} = {
  kg: 1,
  t: 1_000,
  kt: 1_000_000,
}

export const profitLossUnitChoices: { [key in ProfitLossEntryUnit]: string } = {
  perKwhGenerated: "/ kWh Supply to Load",
  perKwhHeatGenerated: "/ kWh Heat Supply to Load",
  perKwhGeneratedTotal: "/ kWh Total",
  perKwhExcess: "/ kWh Electrical Excess",
  perKwhResidual: "/ kWh Unmet Electrical Load",
  perKwhHeatExcess: "/ kWh Heat Excess",
  perKwhHeatResidual: "/ kWh Unmet Heat Load",
  perKwPeakPa: "/ kWp per Annum",
  perFuelLitre: "/ Litre Fuel Used",
  perFuelKg: "/ kg Fuel Used",
  perFuelKwh: "/ kWh of Fuel Used",
  perHour: "/ hour",
  perDay: "/ day",
  perMonth: "/ month",
  perYear: "/ year",
  perHourRun: "/ hour run",
  perKwhGas: "/ kWh of Gas Used",
  perKwhElectricity: "/ kWh of Electricity Used",
  perKwhOnSite: "/ kWh of Onsite Generation Used",
  perKwhLoss: "/ kWh lost",
  perHourDsr: "/ per hour DSR",
  maintenance: "Error DO NOT select!",
}

export const granularityChoices: { [key in ReportGranularity]: string } = {
  yearly: "Yearly",
  monthly: "Monthly",
}

export const profitLossValueTypeLabels: {
  [key in ProfitLossValueType]: string
} = {
  price: "Price",
  numberOf: "Number of",
  cO2e: "KgCO2e per Unit",
}

export const profitLossTypeLabels: { [key in ProfitLossType]: string } = {
  statistic: "Statistic",
  income: "Income",
  expenditure: "Expenditure",
  carbon: "Carbon",
}

export const moduleLabels: { [key in ScenarioModule]: string } = {
  battery: "Battery",
  chp: "CHP",
  engineGeneration: "Engine Generation",
  gasEngine: "Gas Engine Generation",
  load: "Electrical Load",
  electricalLoad: "Electrical Load",
  heatLoad: "Heat Load",
  solar: "Solar",
  wind: "Wind Turbine",
  carbon: "Carbon",
  headless: "Scenario",
  heatSource: "Heating",
  hotwell: "Hotwell",
}

export const installationLabels: { [key in ScenarioModule]?: string } = {
  battery: "battery bank",
  chp: "engine installation",
  engineGeneration: "engine installation",
  gasEngine: "gas engine installation",
  solar: "solar array",
  wind: "wind turbine installation",
  heatSource: "heat source",
  hotwell: "hotwell",
}

export const moduleInstallationMap: {
  [key in ScenarioModule]?: InstallationType
} = {
  battery: "batteryBank",
  chp: "engineInstall",
  engineGeneration: "engineInstall",
  gasEngine: "gasEngineInstall",
  solar: "solarArray",
  wind: "turbineInstall",
  heatSource: "heatSourceInstall",
  hotwell: "hotwell",
}

// Reverse the keys and values of the moduleInstallationMap
export const installationModuleMap: {
  [key in InstallationType]?: ScenarioModule
} = Object.fromEntries(
  Object.entries(moduleInstallationMap).map(([key, value]) => [value, key]),
)

export const currencyLabels: { [key in ProjectCurrency]: string } = {
  gbp: "£  - GBP - Pounds Sterling ",
  usd: "$ - USD - United States Dollars",
  eur: "€ - EUR - Euro",
}

export const currencyDisplay: { [key in ProjectCurrency]: string } = {
  gbp: "£",
  usd: "$",
  eur: "€",
}

export const pnlTypeClasses = tv({
  base: "font-semibold",
  variants: {
    type: {
      income: "text-green-600",
      expenditure: "text-red-500",
      statistic: "text-gray-700",
      carbon: "text-gray-700",
    },
  },
})

export const ScenarioModules: Record<ScenarioModule, ScenarioModule> = {
  battery: "battery",
  carbon: "carbon",
  chp: "chp",
  engineGeneration: "engineGeneration",
  gasEngine: "gasEngine",
  load: "electricalLoad",
  electricalLoad: "electricalLoad",
  heatLoad: "heatLoad",
  solar: "solar",
  wind: "wind",
  headless: "headless",
  heatSource: "heatSource",
  hotwell: "hotwell",
}

export const outputScenarioModuleOrder: OutputScenarioModule[] = [
  "solar",
  "wind",
  "engineGeneration",
  "gasEngine",
  "heatSource",
  "hotwell",
  "battery",
  "heatSource",
  "headless",
]

export const chartSeriesTypeLabels: { [key in ChartSeriesType]: string } = {
  module: "Module",
  installation: "Installation",
  profitLossEntry: "Profit Loss Entry",
  financialAnalysisLine: "Financial Analysis Entry",
  aggregation: "Aggregation",
}

export const LicenceFlags: Record<LicenceFlag, LicenceFlag> = {
  advancedProfitLoss: "advancedProfitLoss",
  externalUsers: "externalUsers",
  partsLibrary: "partsLibrary",
  solarPanelTracking: "solarPanelTracking",
  valueMapThermal: "valueMapThermal",
  closeUpView: "closeUpView",
  operationCriteria: "operationCriteria",
  levelisedCostOfEnergy: "levelisedCostOfEnergy",
  eventConditions: "eventConditions",
  exportPnL: "exportPnL",
  scenarioForecast: "scenarioForecast",
  scenarioHeadroomAnalysis: "scenarioHeadroomAnalysis",
}

export const LicenceStatuses: Record<LicenceStatus, LicenceStatus> = {
  active: "active",
  gracePeriod: "gracePeriod",
  inactive: "inactive",
}

export const licenceStatusLabels: { [key in LicenceStatus]: string } = {
  active: "Active",
  gracePeriod: "Grace Period",
  inactive: "Inactive",
}

export const licenceFlagLabels: { [key in LicenceFlag]: string } = {
  advancedProfitLoss: "Advanced Profit Loss",
  externalUsers: "External Users",
  partsLibrary: "Parts Library",
  solarPanelTracking: "Solar Panel Tracking",
  valueMapThermal: "Thermal Value Map",
  closeUpView: "Detail View",
  operationCriteria: "Operation Criteria",
  levelisedCostOfEnergy: "Levelised Cost of Energy",
  eventConditions: "Event Conditions",
  exportPnL: "PnL Excel Export",
  scenarioForecast: "Scenario Forecast",
  scenarioHeadroomAnalysis: "Headroom Analysis",
}

export const licenceLimitKeyLabels: { [key in LicenceLimitKeys]: string } = {
  profitLossEntryInScenario: "Profit Loss Entries in Scenario",
  projectGroupInAccount: "Project Groups in Account",
  projectInAccount: "Projects in Account",
  readOnlySeats: "Read Only Seats",
  readWriteSeats: "Read & Write Seats",
}

export const userSeatTypeLabels: { [key in UserSeatType]: string } = {
  readAndWrite: "Edit & Modify",
  readOnly: "Read Only",
}

export const permissionTypeLabels: Record<PermissionType, string> = {
  Account: "Account",
  Battery: "Battery",
  Engine: "Engine",
  GasEngine: "Gas Engine",
  FuelCurve: "Fuel Curve",
  HeatSource: "Heat Source",
  Licence: "Licence",
  LicencePlan: "Licence Plan",
  Manufacturer: "Manufacturer",
  SolarPanel: "Solar Panel",
  WindTurbine: "Wind Turbine",
  GlobalAnnouncement: "Global Announcement",
}

export const accountPermissionTypeLabels: Record<
  AccountPermissionType,
  string
> = {
  ExternalUser: "External User",
  PermissionRole: "Permission Role",
  Project: "Project",
  ProjectGroup: "Project Group",
  User: "User",
  UserGroup: "User Group",
}

export const permissionPresetLabels: Record<PermissionRolePresetType, string> =
  {
    admin: "Admin",
    custom: "Custom",
    editor: "Editor",
    viewer: "Viewer",
  }

export const permissionRolePresetScopeLabels: Record<
  PermissionRolePresetScope,
  string
> = {
  account: "Account",
  global: "Global",
}

export const permissionPresetNoCustomLabels: Record<
  PermissionRolePresetTypeNoCustom,
  string
> = {
  admin: "Admin",
  editor: "Editor",
  viewer: "Viewer",
}

export const externalUserLevelLabels: {
  [key in ExternalUserAccessLevel]: string
} = {
  viewer: "Viewer",
  editor: "Editor",
}

export const permissionRoleSubjectTypeLabels: Record<
  PermissionRoleSubjectType,
  string
> = {
  "*": "All types",
  "Account": "Account",
  "Battery": "Battery",
  "Engine": "Engine",
  "GasEngine": "GasEngine",
  "FuelCurve": "Fuel Curve",
  "HeatSource": "Heat Source",
  "Licence": "Licence",
  "LicencePlan": "Licence Plan",
  "Manufacturer": "Manufacturer",
  "SolarPanel": "Solar Panel",
  "WindTurbine": "Wind Turbine",
  "ExternalUser": "External User",
  "PermissionRole": "Permission Role",
  "Project": "Project",
  "ProjectGroup": "Project Group",
  "User": "User",
  "UserGroup": "User Group",
  "GlobalAnnouncement": "Global Announcement",
}

export const moduleLocationMap: Partial<{ [key in ScenarioModule]: string }> = {
  battery: "batteries",
  chp: "engines",
  engineGeneration: "engines",
  gasEngine: "gas-engines",
  solar: "solar",
  wind: "wind",
  headless: "headless",
  heatSource: "heat-sources",
  hotwell: "hotwell",
}

export const financialAnalysisLineNames: Record<
  FinancialAnalysisLineId,
  string
> = {
  capex: "Capital Expenditure",
  cash_flow: "Cash Flow (CF)",
  depreciation: "Depreciation",
  earnings_before_tax: "Earnings Before Taxation",
  expenditure: "Expenditure",
  free_cash_flow: "Free Cash Flow (FCF)",
  free_cash_flow_equity: "Free Cash Flow to Equity (FCFE)",
  free_cash_flow_firm: "Free Cash Flow to Firm (FCFF)",
  net_revenue: "Net Revenue",
  interest: "Interest Payments",
  capital_payment: "Capital Source Payments",
  net_profit: "Net Profit",
  principal: "Principal Payments",
  revenue: "Revenue",
  tax: "Tax",
  discounted_cash_flow: "Discounted Cash Flow (DCF)",
  added_debt: "Added Debt",
}

export const gridFrequencyLabels: Record<GridFrequency, string> = {
  fiftyHertz: "50Hz",
  sixtyHertz: "60Hz",
}

export const gridFrequencyChoices: Record<GridFrequency, string> = {
  fiftyHertz: "50Hz - Africa, Europe, Asia and Oceania",
  sixtyHertz: "60Hz - Americas",
}

export const announcementTypeLabels: Record<AnnouncementType, string> = {
  neutral: "Neutral",
  info: "Info",
  warning: "Warning",
  success: "Success",
  error: "Error",
  maintenance: "Maintenance",
}

/* Criterion */

export const criterionValueSourceTypeLabels: Record<SourceType, string> = {
  valueMap: "Value Map",
  installation: "Installation",
  demand: "Demand",
}

export const criterionModuleSourceLabels: Record<ModuleSource, string> = {
  electricalGeneration: "Electrical Generation",
  electricalSupply: "Electrical Supply",
  electricalResidual: "Electrical Residual",
  electricalExcess: "Electrical Excess",
  heatGeneration: "Heat Generation",
  heatSupply: "Heat Supply",
  heatExcess: "Heat Excess",
  heatResidual: "Heat Residual",
  gasDemand: "Gas Demand",
  engineLoad: "Engine Load",
  temperature: "Temperature",
  electricalDemand: "Electrical Demand",
  heatDemand: "Heat Demand",
  unmetElectricalDemand: "Unmet Electrical Demand",
  unmetHeatDemand: "Unmet Heat Demand",
}

export const criterionOperatorSymbols: Record<CriterionOperator, string> = {
  greaterThan: ">",
  greaterThanOrEqualTo: ">=",
  lessThan: "<",
  lessThanOrEqualTo: "<=",
}

export const criterionOperatorLabels: Record<CriterionOperator, string> = {
  greaterThan: "Greater Than ( > )",
  greaterThanOrEqualTo: "Greater Than or Equal To ( >= )",
  lessThan: "Less Than ( < )",
  lessThanOrEqualTo: "Less Than or Equal To ( <= )",
}

export const criterionActionLabels: Record<CriterionAction, string> = {
  charge: "Charge",
  discharge: "Discharge",
  disconnect: "Disconnect",
  forceHeating: "Force Heating",
  forceActive: "Force Active",
  forceExport: "Force Export",
  demandSideResponse: "Demand Side Response",
}

/* Event */

export const eventEffectValueTypeLabels: Record<EffectValueType, string> = {
  valueMap: "Value Map",
  constant: "Constant",
}

/* Maintenance */
export const deferralPlanLabels: Record<DeferralPlan, string> = {
  immediate: "Immediate",
  nominated: "Nominated Day",
}

export const dayOfWeekLabels: Record<DayOfWeek, string> = {
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  sunday: "Sunday",
}

export const valueMapTypeLabels: Record<ValueMapType, string> = {
  chp: "Heat Demand",
  load: "Load",
  profitLoss: "Profit and Loss",
}

export const valueMapSourceLabels: Record<ValueMapSource, string> = {
  generated: "Generated",
  uploaded: "Uploaded",
}

export const capitalTypeLabels: Record<CapitalType, string> = {
  loan: "Loan",
  other: "Other",
}

export const partVisibilityLabels: Record<PartVisibility, string> = {
  public: "Public",
  generic: "Generic",
  private: "Private",
}

export const moduleSourceLabels: Record<ModuleSource, string> = {
  electricalGeneration: "kWh",
  electricalSupply: "kWh",
  electricalResidual: "kWh",
  electricalExcess: "kWh",
  heatGeneration: "kWh",
  heatSupply: "kWh",
  heatResidual: "kWh",
  heatExcess: "kWh",
  gasDemand: "kWh",
  temperature: "C",
  engineLoad: "kWh",
  electricalDemand: "kWh",
  heatDemand: "kWh",
  unmetElectricalDemand: "kWh",
  unmetHeatDemand: "kWh",
}

export const acceptableModuleSourceLabelsPerSource: Record<
  SourceType,
  ModuleSource[]
> = {
  valueMap: [],
  installation: [
    "electricalGeneration",
    "electricalSupply",
    "electricalDemand",
    "electricalExcess",
    "heatGeneration",
    "heatSupply",
    "heatResidual",
    "heatExcess",
    "gasDemand",
    "temperature",
    "engineLoad",
  ],
  demand: [
    "electricalDemand",
    "heatDemand",
    "unmetElectricalDemand",
    "unmetHeatDemand",
  ],
}
