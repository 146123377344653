import { type QueryClient, matchQuery } from "@tanstack/vue-query"

type InvalidateFilters = Parameters<QueryClient["invalidateQueries"]>[0]
type InvalidateOptions = Parameters<QueryClient["invalidateQueries"]>[1]

export function getQueryClient() {
  return useNuxtApp().$queryClient
}

export function invalidateQueries(
  queryKey: string | string[],
  filters?: InvalidateFilters,
  options?: InvalidateOptions,
) {
  return getQueryClient().invalidateQueries(
    {
      ...filters,
      queryKey: Array.isArray(queryKey) ? queryKey : [queryKey],
    },
    options,
  )
}

export function useQuerySubscribe<T>(
  queryKey: string | string[],
  callback: (object: T | undefined) => void,
) {
  const queryClient = getQueryClient()

  const unsubscribe = queryClient.getQueryCache().subscribe((event) => {
    if (
      event.type === "updated" &&
      event.action.type === "success" &&
      matchQuery(
        { queryKey: Array.isArray(queryKey) ? queryKey : [queryKey] },
        event.query,
      )
    ) {
      callback(event.action.data)
    }
  })

  return {
    unsubscribe,
  }
}
