<script setup lang="ts">
import { tv } from "tailwind-variants"

defineProps<{ dotClass?: string; light?: boolean }>()

const classes = tv({
  base: "bounce bg-white",
  variants: {
    light: {
      true: "bg-forest-700",
    },
  },
})
</script>

<template>
  <!-- Courtesy of https://tobiasahlin.com/spinkit/-->
  <div class="spinner space-x-1 text-center">
    <div
      v-for="n in 3"
      :key="n"
      class="inline-block h-3 w-3 rounded-full"
      :class="classes({ light, class: `bounce-${n} ${dotClass}` })"></div>
  </div>
</template>

<style scoped>
.spinner .bounce {
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce-1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce-2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
</style>
