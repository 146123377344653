export const announcementsQueryKey = "announcements"
export const accountsQueryKey = "accounts"
export const accountUsageStatsQueryKey = "accountUsageStats"
export const usersQueryKey = "users"
export const userGroupsQueryKey = "userGroups"
export const licencePlansQueryKey = "licencePlans"
export const licencesQueryKey = "licences"
export const invitesQueryKey = "invites"
export const externalUsersQueryKey = "externalUsers"

export const inviteMetaQueryKey = "inviteMeta"
export const externalInviteMetaQueryKey = "externalUserInviteMeta"
export const passwordResetMetaQueryKey = "passwordResetMeta"

export const batteriesQueryKey = "batteries"
export const batteryMetaQueryKey = "batteryMeta"
export const enginesQueryKey = "engines"
export const engineMetaQueryKey = "engineMeta"
export const fuelCurveRelationshipsQueryKey = "fuelCurveRelationships"
export const gasEnginesQueryKey = "gasEngines"
export const gasEngineMetaQueryKey = "gasEngineMeta"
export const heatSourcesQueryKey = "heatSources"
export const heatSourceMetaQueryKey = "heatSourceMeta"
export const manufacturersQueryKey = "manufacturers"
export const solarPanelsQueryKey = "solarPanels"
export const solarPanelMetaQueryKey = "solarPanelMeta"
export const windTurbinesQueryKey = "windTurbines"
export const windTurbineMetaQueryKey = "windTurbineMeta"

export const projectsQueryKey = "projects"
export const scenariosQueryKey = "scenarios"
export const scenarioDetailsQueryKey = "scenarioDetails"
export const valueMapsQueryKey = "valueMaps"
export const profitLossEntriesQueryKey = "profitLossEntries"
export const dashboardsQueryKey = "dashboards"
export const chartsQueryKey = "charts"
export const operationCriterionQueryKey = "operationCriterion"
export const levelisedCostsQueryKey = "levelisedCosts"
export const loadProfileListQueryKey = "loadProfileList"
export const capitalSourcesQueryKey = "capitalSources"
export const eventConditionsQueryKey = "eventConditions"
export const assetEntriesQueryKey = "assetEntries"
export const aggregationsQueryKey = "aggregations"

export const mfaGenerateQueryKey = "mfaGenerate"

export const batteryBanksQueryKey = "batteryBanks"
export const engineInstallsQueryKey = "engineInstalls"
export const gasEngineInstallsQueryKey = "gasEngineInstalls"
export const heatSourceInstallsQueryKey = "heatSourceInstalls"
export const hotwellsQueryKey = "hotwells"
export const solarArraysQueryKey = "solarArrays"
export const windTurbineInstallsQueryKey = "windTurbineInstalls"

export const helpTextQueryKey = "helpText"
export const contentQueryKey = "content"
export const termsOfUseQueryKey = "terms-of-use" // This is a special case as it is matching the URL slug
