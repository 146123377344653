import type { User } from "~/types/api"

export function getDisplayName(user: User | undefined) {
  if (!user) return "---"

  if (user.fullName) return user.fullName

  // If the username is an email, we get the first part of it
  // We can assert as a split will always return an array with at least one element
  const username = user.username.split("@")[0]!

  return username.charAt(0).toUpperCase() + username.slice(1)
}
