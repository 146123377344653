import short from "short-uuid"

export default defineNuxtPlugin(() => {
  const shortIdTranslator = short(short.constants.flickrBase58)

  return {
    provide: {
      toShortId: shortIdTranslator.fromUUID,
      fromShortId: shortIdTranslator.toUUID,
    },
  }
})
