export type PermissionType =
  | "Engine"
  | "GasEngine"
  | "SolarPanel"
  | "WindTurbine"
  | "Battery"
  | "HeatSource"
  | "Manufacturer"
  | "Account"
  | "FuelCurve"
  | "Licence"
  | "LicencePlan"
  | "GlobalAnnouncement"

export const permissionTypes: Record<PermissionType, PermissionType> = {
  Account: "Account",
  Engine: "Engine",
  GasEngine: "GasEngine",
  SolarPanel: "SolarPanel",
  WindTurbine: "WindTurbine",
  Battery: "Battery",
  HeatSource: "HeatSource",
  Manufacturer: "Manufacturer",
  FuelCurve: "FuelCurve",
  Licence: "Licence",
  LicencePlan: "LicencePlan",
  GlobalAnnouncement: "GlobalAnnouncement",
}

export type AccountPermissionType =
  | "User"
  | "UserGroup"
  | "ExternalUser"
  | "Project"
  | "ProjectGroup"
  | "PermissionRole"

export const accountPermissionTypes: Record<
  AccountPermissionType,
  AccountPermissionType
> = {
  User: "User",
  UserGroup: "UserGroup",
  ExternalUser: "ExternalUser",
  Project: "Project",
  ProjectGroup: "ProjectGroup",
  PermissionRole: "PermissionRole",
}

export type PermissionVerb =
  | "Create"
  | "Read"
  | "Update"
  | "Delete"
  | "ReadContents"
  | "CreateContents"
  | "UpdateContents"
  | "DeleteContents"

export const permissionVerbs: Record<PermissionVerb, PermissionVerb> = {
  Create: "Create",
  Read: "Read",
  Update: "Update",
  Delete: "Delete",
  ReadContents: "ReadContents",
  CreateContents: "CreateContents",
  UpdateContents: "UpdateContents",
  DeleteContents: "DeleteContents",
}

export const permissionConstants = {
  Separator: "/",
  IdSeparator: "|",
  Wildcard: "*",
}

export const permissionVerbMutating: Record<PermissionVerb, boolean> = {
  Create: true,
  Update: true,
  Delete: true,
  CreateContents: true,
  UpdateContents: true,
  DeleteContents: true,
  Read: false,
  ReadContents: false,
}

export type PermissionRolePresetType = "viewer" | "editor" | "admin" | "custom"

export type PermissionRolePresetScope = "account" | "global"

export type PermissionRolePresetTypeNoCustom = Exclude<
  PermissionRolePresetType,
  "custom"
>

export type PermissionRoleSubjectType =
  | PermissionType
  | AccountPermissionType
  | "*"
