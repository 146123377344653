import type { Account, AccountUsageStats } from "~/types/api"

export function getAccounts() {
  return httpRequest<Account[]>("/accounts")
}

export function getAccount(id: string) {
  return httpRequest<Account>(`/accounts/${id}`)
}

export function getAccountSelf() {
  return httpRequest<Account>("/accounts/@me")
}

export function getAccountUsageStats(id: string) {
  return httpRequest<AccountUsageStats>(`/accounts/${id}/usage-stats`)
}
