export default [
  "/app/src/components/**/*.{vue,js,jsx,mjs,ts,tsx}",
  "/app/src/components//**/*.{vue,js,jsx,mjs,ts,tsx}",
  "/app/src/layouts/**/*.{vue,js,jsx,mjs,ts,tsx}",
  "/app/src/pages/**/*.{vue,js,jsx,mjs,ts,tsx}",
  "/app/src/plugins/**/*.{js,ts,mjs}",
  "/app/src/composables/**/*.{js,ts,mjs}",
  "/app/src/utils/**/*.{js,ts,mjs}",
  "/app/src/{A,a}pp.{vue,js,jsx,mjs,ts,tsx}",
  "/app/src/{E,e}rror.{vue,js,jsx,mjs,ts,tsx}",
  "/app/src/app.config.{js,ts,mjs}",
  "/app/.nuxt/content-cache/parsed/**/*.{md,yml,yaml,json}"
]