<script setup lang="ts">
import type { NuxtLinkProps } from "nuxt/app"

import { tv } from "tailwind-variants"

const props = defineProps<{
  to?: string
  target?: NuxtLinkProps["target"]
  class?: string
  disabled?: boolean
}>()

const classes = tv({
  base: "underline text-forest-700 hover:no-underline select-none",
  variants: {
    disabled: {
      true: "cursor-not-allowed text-dusk-300",
    },
  },
})

function onClick(e: MouseEvent) {
  if (props.disabled) {
    e.stopImmediatePropagation()
  }
}
</script>

<template>
  <NuxtLink
    v-if="to && !disabled"
    :class="classes({ class: $props.class, disabled })"
    :to="to"
    :target="target">
    <slot />
  </NuxtLink>
  <button
    v-else
    type="button"
    :class="classes({ class: $props.class, disabled })"
    @click="onClick">
    <slot />
  </button>
</template>
